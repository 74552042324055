import axios from "axios";

const BASE_URL = `https://api.studicheckin.afink.dev/`;

/**
 * Sends a DELETE request to the specified path using axios.
 * @param {string} path - The path to send the DELETE request to.
 * @returns {Promise} - A promise that resolves to the response data if the request is successful.
 * @throws {Error} - If an error occurs while sending the DELETE request.
 */
const DeleteRequest = async (path) => {
  try {
    console.log("Sending DELETE request...");
    const response = await axios.delete(`${BASE_URL}${path}`);
    console.log("Response ", response.data);
    console.log("DELETE request was successful!");
    return response;
  } catch (error) {
    console.error("Error while sending DELETE request:", error);
    throw new Error("Fehler beim Senden des DELETE-Anforderung");
  }
};

export default DeleteRequest;
