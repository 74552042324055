// NumberDisplay.js
import React from 'react';
import styled from 'styled-components';

const StyledNumberDisplay = styled.div`
  font-size: 48px;
  font-weight: bold;
  margin-left: -10px;
  color: #000000;
`;

/**
 * Displays a number in a styled component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.number - The number to be displayed.
 * @returns {JSX.Element} The rendered NumberDisplay component.
 */
const NumberDisplay = ({ number }) => {
  return (
    <StyledNumberDisplay>
      <span>{number}</span>
    </StyledNumberDisplay>
  );
};

export default NumberDisplay;
