/**
 * @fileoverview renders the scanner Overview page
 *
 * @returns {JSX.Element} ScannerOverviewTable
 */

import React from "react";
import styled from "styled-components";
import EnhancedToolbar from "./table";
import MenuButton from "../../../components/menu";

/**
 * Renders the room overview table component.
 *
 * @returns {JSX.Element} The room overview table component.
 */
const RoomOverviewTable = () => {
  return (
    <Container>
      <MenuButton />
      <EnhancedToolbar />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  color: #000;
  font-weight: 600;
  padding: 20px 100px;
  min-height: 72vh;
`;

export default RoomOverviewTable;
