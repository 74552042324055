import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAuth } from "../../../../auth/AuthContext";

const RoleSelection = ({ onSelect, resetSelectedRole }) => {
  const roles = ["Student", "Verwalter", "Admin"];
  const [selectedRole, setSelectedRole] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const { isAdmin } = useAuth();

  /**
   *@brief resets the selected role when resetSelectedRole changes
   */
  useEffect(() => {
    setSelectedRole("");
  }, [resetSelectedRole]);

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
    setAnchorEl(null);
    console.log("Role selected:", role);
    onSelect(role);
  };

  return (
    <Container>
      <label htmlFor="roles" style={labelStyle}>
        Rolle:
      </label>
      <Button
        id="role-button"
        aria-label="Kurse"
        aria-controls="role-menu"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        endIcon={<KeyboardArrowDownIcon />}
        style={{
          color: "black",
          border: "1px solid #E2001A",
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
          textTransform: "none",
          fontSize: "20px",
        }}
      >
        {selectedRole || "Rolle"}
      </Button>

      <Menu
        id="role-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {roles.map((role) => {
          if (isAdmin || role === "Student" || (role === "Admin" && isAdmin)) {
            return (
              <MenuItem key={role} onClick={() => handleRoleSelect(role)}>
                {role}
              </MenuItem>
            );
          } else {
            return null;
          }
        })}
      </Menu>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const labelStyle = {
  marginRight: "10px",
  width: "140px",
  minWidth: "140px",
};

export default RoleSelection;
