export const menuData = [
    {
        title: "Home",
        path: "/",
        cName: "nav-text",
    },
    {
        title: "Vorlesungen",
        path: "/lecture",
        cName: "nav-text",
    },
    {
        title: "Anwesenheit",
        path: "/attendance",
        cName: "nav-text",
    },
    {
        title: "Raumübersicht",
        path: "/roomoverview",
        cName: "nav-text",
    },
    {
        title: "Kursübersicht",
        path: "/courseoverview",
        cName: "nav-text",
    },
    {
        title: "Geräteübersicht",
        path: "/scanneroverview",
        cName: "nav-text",
    },
    {
        title: "Hilfe",
        path: "/help",
        cName: "nav-text",
    },
    {
        title: "Kalender",
        path: "/calendar",
        cName: "nav-text",
    },
    {
        title: "Studierende",
        path: "/studentoverview",
        cName: "nav-text",
    },
    {
        title: "Accounts",
        path: "/accounts",
        cName: "nav-text",
    },
    ];