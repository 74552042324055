import React, { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "styled-components";
import { useAuth } from "../../../../auth/AuthContext";
import GetRequest from "../../../../components/getFormular";
import ConfirmDialog from "../../../../components/confirmDialog";
import ErrorDialog from "../../../../components/errorDialog";
import SuccessDialog from "../../../../components/successDialog";
import DeleteRequest from "../../../../components/deleteFormula";


/**
 * CourseFilter component.
 *
 * This component is responsible for rendering a course filter and handling the deletion of courses.
 * It fetches all existing courses and allows the user to select a course to delete.
 * When the user confirms the deletion, it sends a delete request to remove the selected course.
 *
 * @returns {JSX.Element} The CourseFilter component.
 */
const CourseFilter = () => {
  const [selectedCourse, setSelectedCourse] = useState("");
  const [courseIds, setCourseIds] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [courses, setCourses] = useState([]);
  const { facultyId } = useAuth();
  const [openDialog, setOpenDialog] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  /**
   * @brief fetch all existing courses if admin, else fetch only courses of the faculty from manager
   */
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        if (facultyId) {
          // Wenn facultyId vorhanden ist, nur die Kurse der entsprechenden Fakultät abrufen
          const response = await GetRequest(`faculties/${facultyId}/courses`);
          const coursesData = response.data;
          const coursesShortNames = coursesData.map(
            (course) => course.shortName
          );
          const coursesMap = coursesData.reduce((acc, course) => {
            acc[course.shortName] = course.id;
            return acc;
          }, {});

          setCourses(coursesShortNames);
          setCourseIds(coursesMap);
        } else {
          // if admin, fetch all courses
          const response = await GetRequest("courses");
          const coursesData = response.data;
          const coursesShortNames = coursesData.map(
            (course) => course.shortName
          );
          const coursesMap = coursesData.reduce((acc, course) => {
            acc[course.shortName] = course.id;
            return acc;
          }, {});

          setCourses(coursesShortNames);
          setCourseIds(coursesMap);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchCourses();
  }, [facultyId]);

  /**
   * @brief called when the submit button is clicked
   */
  const handleSubmitButton = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseSuccessDialog = () => {
    setSuccessDialogOpen(false);
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  /**
   * @brief handles the confirmation of the dialog and sends the delete request for deleting a course
   */
  const handleConfirm = async () => {
    setOpenDialog(false);
    setSelectedCourse("");
    try {
      const response = await DeleteRequest(
        `courses/${courseIds[selectedCourse]}`
      );
      if (response.status === 200) {
        setSuccessDialogOpen(true);
        setSelectedCourse("");
      } else {
        setErrorDialogOpen(true);
      }
    } catch (error) {
      setErrorDialogOpen(true);
      console.error("Error:", error);
    }
  };

  /**
   * @brief called when a course is selected
   * @param {*} course
   */
  const handleCourseSelect = async (course) => {
    setSelectedCourse(course);
    setAnchorEl(null);
  };

  return (
    <div>
      <Container>
        <label htmlFor="courses" style={labelStyle}>
          Kurs:{" "}
        </label>
        <Button
          id="course-button"
          aria-label="Kurse"
          aria-controls="course-menu"
          aria-haspopup="true"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<KeyboardArrowDownIcon />}
          style={buttonStyle}
        >
          {selectedCourse || "Kurs"}
        </Button>
        <Menu
          id="course-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {courses.map((course) => (
            <MenuItem key={course} onClick={() => handleCourseSelect(course)}>
              {course}
            </MenuItem>
          ))}
        </Menu>
      </Container>
      <Container>
        {/* submit button */}
        {selectedCourse ? (
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleSubmitButton}
            style={{
              backgroundColor: "#E2001A",
              marginTop: "20px",
            }}
          >
            Kurs löschen
          </Button>
        ) : null}
      </Container>

      {/* confirmation dialog */}
      <ConfirmDialog
        open={openDialog}
        onClose={handleClose}
        onConfirm={handleConfirm}
        confirmText="Möchten Sie diesen Kurs löschen?"
        dialogTitle="Kurs löschen"
      />
      {/* success pop up */}
      <SuccessDialog
        open={successDialogOpen}
        onClose={handleCloseSuccessDialog}
        text="Kurs wurde erfolgreich gelöscht"
      />
      <ErrorDialog
        open={errorDialogOpen}
        onClose={handleCloseErrorDialog}
        errorMessage="Kurs konnte nicht gelöscht werden."
      />
    </div>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const labelStyle = {
  marginRight: "10px",
  width: "140px",
  minWidth: "140px",
};

const buttonStyle = {
  color: "black",
  border: "1px solid #E2001A",
  fontFamily: "Arial, sans-serif",
  textTransform: "none",
  fontSize: "20px",
};

export default CourseFilter;
