/**
 * @fileoverview Login formular component for the login page.
 * @description allows admins, students to login to the corresponding dashboard
 */

import React, { useState } from "react";
import { Button, TextField, Typography, Container, Link } from "@mui/material";
import PostRequest from "../../../components/postFormula";

/**
 * this function returns the login formular and handles the login request for a user
 * @returns {JSX.Element} LoginFormula
 */
const LoginFormula = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await PostRequest({
        data: { email, password },
        path: `auth/login`,
      });
      //store token in local storage
      localStorage.setItem("Authorization", response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      // redirect user to dashboard
      window.location.href = "/";
    } catch (error) {
      console.error(error.message);
      // Handle error here
      setError("Fehler beim Anmelden");
    }
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" align="center" gutterBottom>
        Anmelden
      </Typography>
      <Typography variant="body2" align="center" gutterBottom>
        Melden Sie sich mit Ihrer Hochschul-Email an, um auf das Dashboard zu
        zugreifen.
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          sx={{ marginBottom: 2 }}
          label="Email"
          variant="outlined"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          fullWidth
          required
        />
        <TextField
          sx={{ marginBottom: 2 }}
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          fullWidth
          required
        />
        <Typography variant="body2" align="center" gutterBottom>
          Passwort vergessen?{" "}
          <Link href="/setemail" underline="hover" color="secondary">
            Passwort zurücksetzen
          </Link>
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <Button
          sx={{
            color: "white",
            backgroundColor: "#E2001A",
            "&:hover": {
              backgroundColor: "#E2001A",
            },
          }}
          type="submit"
          variant="contained"
          fullWidth
        >
          Anmelden
        </Button>
      </form>
    </Container>
  );
};

export default LoginFormula;
