import React from "react";
import Navbar from "../../components/header";
import Footer from "../../components/footer";
import HelpText from "./components/helpPage";
import styled from "styled-components";


/**
 * Renders the HelpPage component.
 *
 * @returns {JSX.Element} The rendered HelpPage component.
 */
const HelpPage = () => {
  return (
    <div>
      <Navbar />
      <Helpdiv>
      <HelpText />
      </Helpdiv>
      <Footer />
    </div>
  );
};

const Helpdiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 5%; /* Default padding */

  @media screen and (min-width: 800px) {
    padding: 0 15%; /* Add 10% padding on screens larger than 768px */
  }
`;

export default HelpPage;
