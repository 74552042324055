import React from "react";
import styled from "styled-components";

/**
 * Footer component.
 * Renders the footer section of the website.
 *
 * @returns {JSX.Element} The rendered Footer component.
 */
const Footer = () => {
  return (
    <FooterContainer>
      <Image loading="lazy" src="/DHlogo_black.png" alt="logo" />
      <InfoContainer>
        <Title><a href="/help">Impressum</a></Title>
        <Title><a href="/help">Kontakt</a></Title>
      </InfoContainer>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  display: flex;
  border-radius: 0px 0px 0px 0px;
  background-color: #262626;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 80px;
  width: auto;
  height: auto;

  @media (maxWidth: 991px) {
    flex-wrap: wrap;
    padding: 20px; /* Padding für kleinere Bildschirme anpassen */
  }
`;

const Image = styled.img`
  width: 320px; /* Bildgröße ändern */
  height: auto; /* Automatische Höhe beibehalten */
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  color: #6a6a6a;
  text-align: center;
  font: 400 16px Inter, sans-serif;
`;

const Title = styled.div`
  margin-right: 20px; /* Abstand zwischen den Titeln erhöhen */
  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: #E2001A; /* Farbe bei Hover */
    }
  }
`;

export default Footer;
