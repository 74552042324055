import React, { useState } from "react";
import RoleSelection from "./roleSelection";
import AccountDetailsManager from "./accountDetailsManager";
import AccountDetailsStudent from "./accountDetailsStudent";

/**
 * Represents a form for adding a new account.
 * @component
 */
const AddAccountForm = () => {
  const [selectedRole, setSelectedRole] = useState("");
  const [, setResetSelectedRole] = useState(false);

  /**
   * Handles the selection of the role.
   * @param {string} role - The selected role.
   */
  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  /**
   * Handles the reset of the selected role.
   */
  const handleResetSelectedRole = () => {
    setResetSelectedRole(true);
    setTimeout(() => {
      setResetSelectedRole(false);
    }, 100);
  };

  return (
    <form>
      <h3>
        Geben Sie hier die benötigten Daten an, um einen neuen Account zu
        erstellen
      </h3>
      <RoleSelection onSelect={handleRoleSelect} />

      {selectedRole === "Verwalter" || selectedRole === "Admin" ? (
        <AccountDetailsManager
          selectedRole={selectedRole}
          handleResetSelectedRole={handleResetSelectedRole}
        />
      ) : null}

      {selectedRole === "Student" && (
        <AccountDetailsStudent
          handleResetSelectedRole={handleResetSelectedRole}
        />
      )}
    </form>
  );
};

export default AddAccountForm;
