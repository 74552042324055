import React from "react";
import styled from "styled-components";
import MenuButton from "../../../components/menu";

/**
 * Renders the HelpText component.
 * This component displays helpful information about the Studi Check In platform for students and administrators at the Dualen Hochschule Baden-Württemberg (DHBW).
 * It provides an overview of the platform's features and benefits for both students and administrators.
 * It also includes contact information for support and assistance.
 */
function HelpText() {
  return (
    <Container>
      <MenuButton />
      <Title>Hilfe</Title>
      <Content>
        Herzlich willkommen auf der Studi Check In Plattform, dem zentralen Anlaufpunkt für Student*innen und Verwalter*innen an der Dualen Hochschule Baden-Württemberg (DHBW).
        <br />
        <br />
        DHBW Studi Check In ist ein Portal, das speziell für Student*innen und Verwalter*innen entwickelt wurde, um die Anwesenheitsverwaltung für Vorlesungen zu optimieren. Das System bietet eine Plattform, auf der sich Student*innen einloggen können, um ihre Anwesenheit für jede Vorlesung zu bestätigen und die Verwalter*innen eine Übersicht über die Anwesenheiten bekommen.
        <br />
        <br />
        Für Student*innen:
        <br />
        Schnelle Anwesenheitsbestätigung: Mit nur wenigen Klicks kannst du dich für deine Vorlesungen anmelden und deine Anwesenheit bestätigen. Schluss mit manuellen Listen und zeitaufwändigen Prozessen!
        <br />
        Übersicht über deinen Studienverlauf: Behalte den Überblick über deine besuchten Vorlesungen und erhalte hilfreiche Statistiken, die dir dabei helfen, deine Studienziele zu erreichen.
        <br />
        <br />
        Für Verwalter*innen:
        <br />
        Effiziente Anwesenheitsverwaltung: Erhalte eine sofortige Übersicht über die Anwesenheit der Studierenden in Echtzeit. Unser System erleichtert die Verwaltung und ermöglicht es, schnell auf unvorhergesehene Änderungen zu reagieren.
        <br />
        Automatisierte Berichte: Generiere mühelos Berichte und Analysen, um Einblicke in die Anwesenheitstrends zu gewinnen und gezielte Maßnahmen zur Verbesserung der Studienbedingungen zu ergreifen.
        <br />
        <br />
        DHBW Studi Check In ist der Schlüssel zu einem effizienten und transparenten Anwesenheitsmanagement. Mach mit und erlebe eine neue Ära der Anwesenheitsverwaltung an der DHBW!
      </Content>
      <Subtitle>Kontakt</Subtitle>
      <ContactInfo>
        Um Ihnen einen effizienten und umfassenden Service zu bieten, läuft der gesamte Support und jegliche Hilfeanfragen im Zusammenhang mit dieser Verwaltungswebseite des Studi-Checkins ausschließlich über die E-Mail-Adresse
        <br />
        <br />
        <span style={{ fontWeight: 600, fontSize: "36px", '@media (maxWidth: 991px)': { fontSize: "18px" } }}>support@studicheckin.de</span>
        <br />
        <br />
        Unser engagiertes Supportteam steht Ihnen zur Verfügung, um Sie bei sämtlichen Anliegen zu unterstützen. Egal, ob es sich um technische Herausforderungen, Fragen zur optimalen Nutzung der Webseite oder administrative Anfragen handelt.
        <br />
        Wir sind darauf spezialisiert, Ihnen kompetent und zeitnah weiterzuhelfen. Bitte zögern Sie nicht, uns unter der genannten E-Mail-Adresse zu kontaktieren. Wir sind bestrebt, Ihre Anfragen so schnell wie möglich zu bearbeiten und sicherzustellen, dass Sie die bestmögliche Unterstützung erhalten.
        <br />
      </ContactInfo>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  color: #000;
  font-weight: 600;
  padding: 20px 20px;
`;

const Title = styled.div`
  width: 100%;
  font-size: 48px;
  font-family: Inter, sans-serif;
  justify-content: center;

  @media (maxWidth: 991px) {
    maxWidth: 100%;
    font-size: 40px;
  }
`;

const Content = styled.div`
  font-family: Asap, sans-serif;
  font-weight: 400;
  line-height: 26px;
  margin-top: 42px;

  @media (maxWidth: 991px) {
    maxWidth: 100%;
    margin: 40px 6px 0 0;
  }
`;

const Subtitle = styled.div`
  margin-top: 67px;
  width: 100%;
  font-size: 48px;
  font-family: Inter, sans-serif;

  @media (maxWidth: 991px) {
    maxWidth: 100%;
    margin-top: 40px;
    font-size: 40px;
  }
`;

const ContactInfo = styled.div`
  font-family: Asap, sans-serif;
  font-weight: 400;
  line-height: 26px;
  margin-top: 43px;
  width: 100%;

  @media (maxWidth: 991px) {
    maxWidth: 100%;
    margin-top: 40px;
  }
`;

export default HelpText;