/**
 * @fileoverview table component for room overview page
 * @description it displays the room of and whether it is occupied or not
 * it uses a predefined table from material ui that is enhanced with additional functions
 *
 * @license MIT
 */

import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import { useEffect } from "react";
import PatchRequest from "../../../components/patchFormula";
import PostRequest from "../../../components/postFormula";
import DeleteRequest from "../../../components/deleteFormula";
import GetRequest from "../../../components/getFormular";
import DropdownSelection from "../../../components/staticRoleSelectionTemplate";
import SuccessDialog from "../../../components/successDialog";
import ErrorDialog from "../../../components/errorDialog";
import { useAuth } from "../../../auth/AuthContext";

import moment from "moment";
import { set } from "date-fns";

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

/**
 * @description define the head cells of the table you want to display
 */
const headCells = [
  {
    id: "room",
    numeric: false,
    disablePadding: true,
    label: "Raum",
  },
  {
    id: "lesson",
    numeric: false,
    disablePadding: false,
    label: "Vorlesung",
  },
  {
    id: "occupied",
    numeric: false,
    disablePadding: false,
    label: "Belegt",
  },
  {
    id: "freeUntil",
    numeric: false,
    disablePadding: false,
    label: "Frei bis",
  },
];

/**
 * @description component that creates the table head
 * @param {*} props
 */
function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.label === "Belegt" ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

/**
 * @description component that creates the toolbar of the table
 * @param {*} props
 * @returns
 */
function EnhancedTableToolbar(props) {
  const { numSelected, setSelected, selected } = props;
  const { isAdmin } = useAuth();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [changedRoomName, setChangedRoomName] = React.useState("");
  const [newRoomName, setNewRoomName] = React.useState("");
  const [selectedRoomOption, setSelectedRoomOption] = React.useState("");
  const [openCreateRoom, setOpenCreateRoom] = React.useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);

  const roomOptions = ["Raumname ändern", "Raum löschen"];

  const [createNoRoom, setCreateNoRoom] = React.useState(false);
  const [changeNoRoom, setChangeNoRoom] = React.useState(false);
  /**
   * @description function that gets called when the edit button is clicked for changing the scanner name
   */

  /**
   * @brief opens the dialog when user presses edit button
   */
  const handleEdit = async () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setChangedRoomName("");
    setCreateNoRoom(false);
    setOpenDialog(false);
  };

  const handleCloseCreateDialog = () => {
    setNewRoomName("");
    setCreateNoRoom(false);
    setOpenCreateRoom(false);
  };

  /**
   * @brief refreshes the page to update the changes
   */
  const handleCloseSuccessDialog = () => {
    setSuccessDialogOpen(false);
    window.location.reload();
  };

  /**
   * @brief refreshes the page to update the changes
   */
  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
    window.location.reload();
  };

  /**
   * @brief gets called when user selects his choice from the dropdown
   * @param {*} selectedRoomOption
   */
  const handleRoomSelection = (selectedRoomOption) => {
    console.log("Selected Option:", selectedRoomOption);
    setSelectedRoomOption(selectedRoomOption);
  };

  /**
   * @brief gets called when a user confirms the deletion of the selected rooms
   */
  const handleDeleteRoom = async () => {
    setOpenDialog(false);
    try {
      await Promise.all(
        selected.map(async (id) => {
          const response = await DeleteRequest(`rooms/${id}`);
          console.log(response);
          console.log(`Raum mit der ID ${id} wurde gelöscht.`);
        })
      );
      console.log("Alle ausgewählten Räume wurden erfolgreich gelöscht.");
      setSuccessDialogOpen(true);
    } catch (error) {
      console.error("Fehler beim Löschen der Räume:", error);
      setErrorDialogOpen(true);
    }

    setSelected([]);
  };

  /**
   * @brief gets called when a user confirms the change of the selected rooms
   */
  const handleChangeRoom = async () => {
    // check whether changedRoomName is empty
    if (!changedRoomName) {
      setChangeNoRoom(true);
      return;
    }

    try {
      await Promise.all(
        selected.map(async (id) => {
          const response = await PatchRequest({
            data: { name: changedRoomName },
            path: `rooms/${id}`,
          });
          console.log(response);
          console.log(`Raum mit der ID ${id} wurde aktualisiert.`);
        })
      );
      console.log("Alle Räume wurden erfolgreich aktualisiert.");
      setOpenDialog(false);
      setSuccessDialogOpen(true);
    } catch (error) {
      console.error("Fehler beim Aktualisieren der Räume:", error);
      setOpenDialog(false);
      setErrorDialogOpen(true);
    }

    setSelected([]);
  };

  /**
   * @brief gets called when users confirms create of a new room
   * @description it sends a post request to the server to create a new room
   */
  const handleCreateRoom = async () => {
    if (!newRoomName) {
      setCreateNoRoom(true);
      return;
    }

    try {
      const response = await PostRequest({
        data: { name: newRoomName },
        path: "rooms",
      });
      if (response.status === 201) {
        console.log("Raum erfolgreich erstellt.");
        setOpenCreateRoom(false);
        setSuccessDialogOpen(true);
      } else {
        console.error("Fehler beim Erstellen des Raums:", response);
        setOpenCreateRoom(false);
        setErrorDialogOpen(true);
      }
    } catch (error) {
      console.error("Fehler beim Erstellen des Raums:", error);
      setOpenCreateRoom(false);
      setErrorDialogOpen(true);
    }
  };

  /**
   * @brief gets called when user presses confirm button
   *  @description it either calls the function to change, or delete a room
   */
  const handleConfirm = async () => {
    if (selectedRoomOption === "Raumname ändern") {
      handleChangeRoom();
    } else if (selectedRoomOption === "Raum löschen") {
      handleDeleteRoom();
    }
  };

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Raumübersicht
          </Typography>
        )}

        {isAdmin && (
          <Tooltip title="Edit">
            <IconButton
              onClick={() => {
                if (numSelected > 0) {
                  handleEdit();
                } else {
                  setOpenCreateRoom(true);
                }
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
      {/* gets opened when user presses edit button */}
      {/* choice between changing, deleting a selected room only for admins */}

      <Dialog open={openDialog} onClose={handleClose} maxWidth="lg">
        <DialogTitle>Räume bearbeiten</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <DropdownSelection
              options={roomOptions}
              onSelect={handleRoomSelection}
              label={"Auswahl"}
            />
          </DialogContentText>

          {/* formula for changing the name of a room */}
          {selectedRoomOption === "Raumname ändern" && (
            <>
              <DialogContent>
                <DialogContentText>
                  Geben Sie hier den veränderten Raumnamen ein:
                </DialogContentText>
                <DialogContentText style={{ color: "red" }}>
                  ⚠ Achtung: Diese Änderung betrifft alle ausgewählten Räume!{" "}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Neuer Raumname"
                  type="text"
                  fullWidth
                  value={changedRoomName}
                  onChange={(e) => setChangedRoomName(e.target.value)}
                />
                {changeNoRoom && (
                  <DialogContentText style={{ color: "red" }}>
                    Bitte geben Sie einen Raumnamen ein
                  </DialogContentText>
                )}
              </DialogContent>
            </>
          )}
          {/* formula for deleting an existing room*/}
          {selectedRoomOption === "Raum löschen" && (
            <>
              <DialogContent>
                <DialogContentText>
                  Sind Sie sicher, dass Sie diesen Raum/Räume löschen möchten?
                </DialogContentText>
                <DialogContentText style={{ color: "red" }}>
                  ⚠ Achtung: Diese Aktion kann nicht rückgängig gemacht werden
                  und betrifft alle ausgewählten Räume!{" "}
                </DialogContentText>
              </DialogContent>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={handleConfirm} color="primary">
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialog for creating a new room */}
      <Dialog
        open={openCreateRoom}
        onClose={() => setOpenCreateRoom(false)}
        maxWidth="lg"
      >
        <DialogTitle>Neuen Raum erstellen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Geben Sie hier den neuen Raumnamen ein:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Neuer Raumname"
            type="text"
            fullWidth
            value={newRoomName}
            onChange={(e) => setNewRoomName(e.target.value)}
          />
          {createNoRoom && (
            <DialogContentText style={{ color: "red" }}>
              Bitte geben Sie einen Raumnamen ein
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog}>Abbrechen</Button>
          <Button onClick={handleCreateRoom} color="primary">
            Erstellen
          </Button>
        </DialogActions>
      </Dialog>

      {/* success pop up */}
      <SuccessDialog
        open={successDialogOpen}
        onClose={handleCloseSuccessDialog}
        text="Aktion erfolgreich ausgeführt"
      />
      <ErrorDialog
        open={errorDialogOpen}
        onClose={handleCloseErrorDialog}
        errorMessage="Die gewünschte Aktion konnte nicht durchgeführt werden"
      />
    </>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

/**
 * component that gets imported in parent component
 * uses Toolbar, Head components to include more functions
 * @returns {JSX.Element}
 */
/**
 * A table component that displays room data.
 *
 * @returns {JSX.Element} The rendered table component.
 */
export default function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Raum");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  // rows that are displayed in the table
  const [rows, setRows] = React.useState([]);
  const [, setRoomMap] = React.useState({}); // Objekt zur Zuordnung von ID zu Raum-Namen

  useEffect(() => {
    /**
     * Fetches room data from the server and transforms it before updating the component state.
     * @returns {Promise<void>} A Promise that resolves when the room data has been fetched and processed.
     */
    const fetchRoomData = async () => {
      try {
        const response = await GetRequest("rooms");
        console.log(response.data);
        const desiredRoomKeys = [
          "id",
          "name",
          "lesson",
          "isOccupied",
          "freeUntil",
        ];
        const transformedRooms = response.data.map((room) => {
          const transformedItem = {};
          // get the desired keys from the room object
          desiredRoomKeys.forEach((key) => {
            if (key === "lesson") {
              transformedItem[key] =
                room.lesson && room.lesson.name
                  ? room.lesson.name
                  : "Keine Vorlesung";
            } else if (key === "freeUntil" && room[key]) {
              // Convert freeUntil to German format using Momen
              transformedItem[key] = moment(room[key]).format("HH:mm");
            } else {
              transformedItem[key] = room[key];
            }
          });
          return transformedItem;
        });
        console.log("Transformed Rooms: ", transformedRooms);
        setRows(transformedRooms);

        // create a map for the room ids to room names
        const roomIdMap = {};
        transformedRooms.forEach((room) => {
          roomIdMap[room.name] = room.id;
        });
        console.log("Room ID Map: ", roomIdMap);
        setRoomMap(roomIdMap);
      } catch (error) {
        console.error(error);
      }
    };

    fetchRoomData();
  }, []);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, (a, b) => {
        if (a.isOccupied && !b.isOccupied) {
          return -1;
        }
        if (!a.isOccupied && b.isOccupied) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    // add rows to the dependencies array to ensure that visiblerows is recalculated when rows changes
    [page, rowsPerPage, rows]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          setSelected={setSelected}
          selected={selected}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 600 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.lesson}</TableCell>
                    <TableCell
                      align="right"
                      style={{
                        backgroundColor: "transparent",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: row.isOccupied
                            ? "#E2001A"
                            : "#4caf50",
                          color: "#fff",
                          padding: "5px 10px",
                          borderRadius: "25px",
                        }}
                      >
                        {row.isOccupied ? "Belegt" : "Frei"}
                      </span>
                    </TableCell>{" "}
                    <TableCell
                      component="th"
                      scope="row"
                      padding="none"
                      align="left"
                    >
                      {row.freeUntil}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
