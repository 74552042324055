import React, { useEffect, useState } from "react";
import styled from "styled-components";
import EnhancedToolbar from "./tableAttendanceWeek";
import Filter from "./filterCourse";
import MenuButton from "../../../components/menu";
import GetRequest from "../../../components/getFormular";
import EnhancedTableCheckIn from "./tableExtraLessons";

/**
 * renders the attendance page including the filter and the attendance table
 * @returns {JSX.Element}
 */
const AttendanceTable = () => {
  const [filterCriteria, setFiltercriteria] = useState({});
  const [requestDate, setRequestDate] = useState("");
  const [courseId, setCourseId] = useState("");
  const [, setRows] = useState([]);
  const [tableData, setTableData] = useState([]);

  const handleRequestDateChange = (newRequestDate) => {
    setRequestDate(newRequestDate);
  };

  const handleCourseIdChange = (newCourseId) => {
    setCourseId(newCourseId);
  };

  /**
   * Extracts check-in information for a specific lesson.
   *
   * @param {Array} checkInLessons - The array of check-in lessons data.
   * @param {string} lessonId - The ID of the lesson.
   * @param {string} lessonName - The name of the lesson.
   */
  const extractCheckInInfo = (checkInLessons, lessonId, lessonName) => {
    const desiredKeys = ["student", "attendance"];

    const transformedData = checkInLessons.data.map((student) => {
      const transformedItem = {};
      desiredKeys.forEach((key) => {
        if (key === "student") {
          transformedItem["id"] = student.student.id;
          transformedItem["firstName"] = student.student.firstName;
          transformedItem["lastName"] = student.student.lastName;
        } else if (key === "attendance" && typeof student[key] === "object") {
          transformedItem[key] = student[key][lessonId];
        }
      });
      return transformedItem;
    });
    setRows(transformedData);
    setTableData((prevTableData) => [
      ...prevTableData,
      { rows: transformedData, lessonName: lessonName, lessonId: lessonId },
    ]);
  };

  /**
   * @brief called when requestdate or courseId in the table or Filter changes
   *
   */
  useEffect(() => {
    const getCheckInLessons = async () => {
      // empty the tableData state
      setTableData([]);
      try {
        const checkInLessons = await GetRequest(
          `courses/${courseId}/attendance/enforced/week/${requestDate}`
        );
        if (
          checkInLessons.data.length > 0 &&
          Object.keys(checkInLessons.data[0].attendance).length === 0
        ) {
          console.log("No extra check-Ins needed");
        } else {
          // Extract all lessonIds from attendance object
          const lessonIds = Object.keys(checkInLessons.data[0].attendance);

          // Loop through each lessonId and execute a request for each
          lessonIds.forEach(async (lessonId) => {
            try {
              const lessonInfo = await GetRequest(`lessons/${lessonId}`);
              console.log("LessonName", lessonInfo.data.name);
              extractCheckInInfo(
                checkInLessons,
                lessonId,
                lessonInfo.data.name
              );
            } catch (error) {
              console.error(
                `Error fetching lesson info for lessonId ${lessonId}:`,
                error
              );
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    getCheckInLessons();
  }, [requestDate, courseId]);

  return (
    <Container>
      <MenuButton />
      <Filter
        setFilterCriteria={setFiltercriteria}
        onChangeCourseId={handleCourseIdChange}
      />
      <EnhancedToolbar
        filterCriteria={filterCriteria}
        onRequestDateChange={handleRequestDateChange}
      />
      {tableData.map((data, index) => (
        <EnhancedTableCheckIn
          key={index}
          rows={data.rows}
          lessonName={data.lessonName}
          filterCriteria={filterCriteria}
          lessonId={data.lessonId}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  color: #000;
  font-weight: 600;
  padding: 20px 100px;
  min-height: 100vh;
`;

export default AttendanceTable;
