import React, { useState } from "react";
import styled from "styled-components";
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

/**
 * DropdownSelection component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.options - The options to display in the dropdown menu.
 * @param {Function} props.onSelect - The function to call when an option is selected.
 * @param {string} props.label - The label for the dropdown menu.
 * @returns {JSX.Element} The DropdownSelection component.
 */
const DropdownSelection = ({ options, onSelect, label }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

    /**
   * Handles the selection of an option from the dropdown menu.
   * @param {string} option - The selected option.
   * @returns {void}
   */
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setAnchorEl(null);
    console.log("Option selected:", option);
    onSelect(option);
  };

  return (
    <Container>
      <label htmlFor="options" style={labelStyle}>
        {label || "Auswahl"}:
      </label>
      <Button
        id="option-button"
        aria-label="Auswahl"
        aria-controls="option-menu"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        endIcon={<KeyboardArrowDownIcon />}
        style={{
          color: "black",
          border: "1px solid #E2001A",
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
          textTransform: "none",
          fontSize: "20px",
        }}
      >
        {selectedOption || label || "Auswahl"}
      </Button>

      <Menu
        id="option-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={() => handleOptionSelect(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const labelStyle = {
  marginRight: "10px",
  width: "140px",
  minWidth: "140px",
};

export default DropdownSelection;
