import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { DialogContentText } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import GetRequest from "../../../components/getFormular";
import PostRequest from "../../../components/postFormula";
import PatchRequest from "../../../components/patchFormula";
import ErrorDialog from "../../../components/errorDialog";
import SuccessDialog from "../../../components/successDialog";
import moment from "moment/moment";
import "moment/locale/de";
import DropdownSelection from "../../../components/staticRoleSelectionTemplate";

// for extracting filename from content-disposition header
import axios from "axios";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Nachname",
  },
  {
    id: "Vorname",
    numeric: false,
    disablePadding: false,
    label: "Vorname",
  },
  {
    id: "Montag",
    numeric: false,
    disablePadding: false,
    label: "Montag",
  },
  {
    id: "Dienstag",
    numeric: false,
    disablePadding: false,
    label: "Dienstag",
  },
  {
    id: "Mittwoch",
    numeric: false,
    disablePadding: false,
    label: "Mittwoch",
  },
  {
    id: "Donnerstag",
    numeric: false,
    disablePadding: false,
    label: "Donnerstag",
  },
  {
    id: "Freitag",
    numeric: false,
    disablePadding: false,
    label: "Freitag",
  },
  {
    id: "Samstag",
    numeric: false,
    disablePadding: false,
    label: "Samstag",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

/**
 * A toolbar component for the attendance table.
 *
 * @param {Object} props - The component props.
 * @param {number} props.numSelected - The number of selected students.
 * @param {function} props.setSelected - The function to set the selected students.
 * @param {Array} props.selected - The array of selected student IDs.
 * @param {Date} props.requestDate - The requested date for the attendance table.
 * @param {function} props.setRequestDate - The function to set the requested date.
 * @returns {JSX.Element} The toolbar component.
 */
function EnhancedTableToolbar(props) {
  const { numSelected, setSelected, selected, requestDate, setRequestDate } =
    props;
  const [startDateExcuse, setStartDateExcuse] = React.useState(moment());
  const [endDateExcuse, setEndDateExcuse] = React.useState(moment());
  const [open, setOpen] = React.useState(false);
  const [selectedAttendanceOption, setSelectedAttendanceOption] =
    React.useState("");
  const [attendanceDate, setAttendanceDate] = React.useState(moment());
  const [selectedAttendanceStatus, setSelectedAttendanceStatus] =
    React.useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);

  // format date to display in the title
  const requestDateFormatted = moment(requestDate).format("DD.MM.YYYY");
  const nextFriday = moment(requestDate).add(5, "days").endOf("isoWeek");
  const nextFridayFormatted = nextFriday.format("DD.MM.YYYY");

  const attendanceOptions = ["Entschuldigung setzen", "Anwesenheit setzen"];

  /**
   * @brief called when the user selects an attendance from the dropdown menu
   * @param {*} selectedOption
   */
  const handleAttendanceSelection = (selectedAttendanceOption) => {
    console.log("Selected Option:", selectedAttendanceOption);
    setSelectedAttendanceOption(selectedAttendanceOption);
  };

  /**
   * @brief called when the user selects an attendance status from the dropdown menu
   * @param {*} selectedAttendanceStatus
   */
  const handleAttendanceStatusSelection = (selectedAttendanceStatus) => {
    console.log("Selected Status:", selectedAttendanceStatus);

    switch (selectedAttendanceStatus) {
      case "Abwesend":
        setSelectedAttendanceStatus(false);
        break;
      case "Anwesend":
        setSelectedAttendanceStatus(true);
        break;
      default:
        setSelectedAttendanceStatus(false);
    }
  };

  /**
   * @brief called when the user presses the edit button for selected students
   */
  const handleEdit = async () => {
    console.log(selected); // array of id from selected students
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelected([]);
  };

  const handleCloseSuccessDialog = () => {
    setSuccessDialogOpen(false);
    // refresh page
    window.location.reload();
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
    window.location.reload();
  };

  /**
   * @brief makes the request to the server to create an excuse for the selected students
   */
  const createExcuse = async () => {
    setOpen(false);
    // Iterating through selected array to make separate requests for each studentId
    for (const studentId of selected) {
      const createExcuseData = {
        startsAt: startDateExcuse.format("YYYY-MM-DD"),
        endsAt: endDateExcuse.format("YYYY-MM-DD"),
        studentId: studentId, // Passing individual studentId in an array
      };
      console.log("Excuse Data:", createExcuseData);

      try {
        const response = await PostRequest({
          data: createExcuseData,
          path: `excuses`,
        });
        console.log(response.status);
        if (response.status === 201) {
          setSuccessDialogOpen(true);
        } else {
          setErrorDialogOpen(true);
        }
      } catch (error) {
        console.error("Error:", error);
        setErrorDialogOpen(true);
      }
    }
  };

  /**
   * Creates attendance records for selected students.
   * @async
   * @function createAttendance
   * @returns {Promise<void>}
   */
  const createAttendance = async () => {
    setOpen(false);
    try {
      console.log("Attendance Date:", attendanceDate);
      for (const studentId of selected) {
        const changeAttendanceData = {
          attendance: selectedAttendanceStatus,
        };
        console.log("Attendance Data:", changeAttendanceData);
        const response = await PatchRequest({
          data: { isAttending: changeAttendanceData.attendance },
          path: `students/${studentId}/attendance/${attendanceDate.format(
            "YYYY-MM-DD"
          )}`,
        });
        console.log(response.status);
        if (response.status === 200) {
          setSuccessDialogOpen(true);
        } else {
          setErrorDialogOpen(true);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorDialogOpen(true);
    }
  };

  /**
   * @brief called when the user presses the confirm button in the dialog
   */
  const handleConfirm = async () => {
    console.log(startDateExcuse, endDateExcuse);
    if (selectedAttendanceOption === "Entschuldigung setzen") {
      createExcuse();
    } else if (selectedAttendanceOption === "Anwesenheit setzen") {
      createAttendance();
    } else {
      console.log("No option selected");
      setErrorDialogOpen(true);
    }
  };

  /**
   * @brief changes requestDate to the previous week
   */
  const handlePreviousWeek = () => {
    const previousWeekStartDate = moment(requestDate)
      .subtract(1, "week")
      .startOf("isoWeek");
    const isoPreviousWeekStartDate = previousWeekStartDate.format("YYYY-MM-DD"); // ISO-Format (YYYY-MM-DD)
    console.log(isoPreviousWeekStartDate);
    setRequestDate(isoPreviousWeekStartDate); // Aktualisiere das Datum um eine Woche zurück im ISO-Format
  };

  /**
   * @brief changes requestDate to the next week
   */
  const handleNextWeek = () => {
    console.log("erstes Datum:", requestDate);
    const nextWeekStartDate = moment(requestDate)
      .add(1, "week")
      .startOf("isoWeek");
    const isoNextWeekStartDate = nextWeekStartDate.format("YYYY-MM-DD"); // ISO-Format (YYYY-MM-DD)
    console.log("Datum", isoNextWeekStartDate);
    setRequestDate(isoNextWeekStartDate); // Aktualisiere das Datum um eine Woche im ISO-Format
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%", fontWeight: "bold" }}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          Anwesenheitslisten - {requestDateFormatted} - {nextFridayFormatted}
          <Typography
            sx={{ display: "block", fontWeight: "normal" }}
            variant="h6"
          >
            Wochenüberblick
          </Typography>
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Edit">
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          <Tooltip title="Vorherige Woche">
            <IconButton onClick={handlePreviousWeek}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Nächste Woche">
            <IconButton onClick={handleNextWeek}>
              <ArrowForwardIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle>Anwesenheit bearbeiten</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <DropdownSelection
              options={attendanceOptions}
              onSelect={handleAttendanceSelection}
              label={"Auswahl"}
            />
          </DialogContentText>

          {/* formula for creating an excuse for a student */}
          {selectedAttendanceOption === "Entschuldigung setzen" && (
            <>
              <DialogContentText>
                Wählen Sie das Start- und Enddatum für die Entschuldigung aus.
              </DialogContentText>
              <DialogContentText
                style={{ color: "red", paddingBottom: "16px" }}
              >
                ⚠ Achtung: Diese Änderung betrifft alle ausgewählten Studenten!
              </DialogContentText>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="de"
              >
                <DesktopDatePicker
                  label="Startdatum"
                  value={startDateExcuse}
                  onChange={(newValue) => setStartDateExcuse(newValue)}
                  TextFieldComponent={TextField}
                />
                <DesktopDatePicker
                  label="Enddatum"
                  value={endDateExcuse}
                  onChange={(newValue) => setEndDateExcuse(newValue)}
                  TextFieldComponent={TextField}
                />
              </LocalizationProvider>
            </>
          )}
          {/* formula for changing the attendance of a student */}
          {selectedAttendanceOption === "Anwesenheit setzen" && (
            <>
              <DialogContentText style={{ paddingBottom: "16px" }}>
                Wählen Sie den Status für die Anwesenheit aus.
              </DialogContentText>
              <DialogContentText
                style={{ color: "red", paddingBottom: "16px" }}
              >
                ⚠ Achtung: Diese Änderung betrifft alle ausgewählten Studenten!
                Wählen Sie keinen Status aus, wird der Student automatisch auf
                abwesend gesetzt
              </DialogContentText>

              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="de"
              >
                <DesktopDatePicker
                  label="Datum"
                  value={startDateExcuse}
                  onChange={(newValue) => setAttendanceDate(newValue)}
                  TextFieldComponent={TextField}
                  sx={{ marginBottom: "16px" }}
                />
              </LocalizationProvider>

              <DropdownSelection
                options={["Anwesend", "Abwesend"]}
                onSelect={handleAttendanceStatusSelection}
                label={"Status"}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={handleConfirm} color="primary">
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
      {/* success pop up */}
      <SuccessDialog
        open={successDialogOpen}
        onClose={handleCloseSuccessDialog}
        text="Aktion erfolgreich ausgeführt"
      />
      {/* error pop up */}
      <ErrorDialog
        open={errorDialogOpen}
        onClose={handleCloseErrorDialog}
        errorMessage="Die gewünschte Aktion konnte nicht durchgeführt werden. Dies kann zum Beispiel daran liegen, dass an ausgewähltem Tag keine Vorlesung stattfindet."
      />
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  filterCriteria: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
};

/**
 * Renders an enhanced table component for displaying attendance data.
 * @param {Object} filterCriteria - The filter criteria for the attendance data.
 * @param {Function} onRequestDateChange - The function to handle the request date change.
 * @returns {JSX.Element} The enhanced table component.
 */
export default function EnhancedTable({ filterCriteria, onRequestDateChange }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("lastName");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  const [requestDate, setRequestDate] = React.useState(
    moment().startOf("isoWeek").format("YYYY-MM-DD")
  );
  const [, setDayToDateMap] = React.useState({});

  /**
   * Export attendance data as a PDF.
   * @async
   * @function exportPdf
   * @returns {Promise<void>}
   */
  const exportPdf = async () => {
    console.log("exporting pdf");
    try {
      const response = await axios.get(
        `https://api.studicheckin.afink.dev/courses/${filterCriteria.courseId}/attendance/pdf/usual/week/${requestDate}`,
        {
          responseType: "blob",
        }
      );

      console.log("Response:", response.headers);

      const contentDispositionHeader = response.headers["content-disposition"];
      let filename = "attendance.pdf";

      if (contentDispositionHeader) {
        filename = contentDispositionHeader
          .split(";")
          .find((n) => n.includes("filename="))
          .replace("filename=", "")
          .trim();
        console.log("Filename:", filename);
      } else {
        console.log("Content-Disposition header not found in response.");
      }

      const url = window.URL.createObjectURL(response.data);

      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.style.display = "none";
      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * @brief called when requestdate in the table changes
   * @description sets the requestDate in the parent component and updates the dayToDateMap
   */
  useEffect(() => {
    const weekdays = [
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag",
    ];

    let currentDate = moment(requestDate);
    const updatedDayToDateMap = {};

    weekdays.forEach((weekday) => {
      updatedDayToDateMap[weekday] = currentDate.format("YYYY-MM-DD");
      currentDate.add(1, "day");
    });
    console.log(updatedDayToDateMap);
    setDayToDateMap(updatedDayToDateMap);
    onRequestDateChange(requestDate);

    if (!filterCriteria.courseId) {
      return;
    }

    /**
     * @brief fetches the attendance data from the serve
     */
    const fetchAttendanceWeek = async () => {
      try {
        console.log(requestDate);

        const attendanceResponse = await GetRequest(
          `courses/${filterCriteria.courseId}/attendance/usual/week/${requestDate}`
        );
        console.log(attendanceResponse.data);

        const desiredKeys = ["student", "attendance"];

        const transformedData = attendanceResponse.data.map((student) => {
          const attendance = creatSortedAttendanceObject(
            student.attendance,
            updatedDayToDateMap
          );
          console.log("Attendance", attendance);
          const transformedItem = {};
          desiredKeys.forEach((key) => {
            if (key === "student") {
              transformedItem["id"] = student.student.id;
              transformedItem["firstName"] = student.student.firstName;
              transformedItem["lastName"] = student.student.lastName;
            } else {
              transformedItem["attendance"] = attendance;
            }
          });
          return transformedItem;
        });
        console.log("Transformed Data", transformedData);
        setRows(transformedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAttendanceWeek();
  }, [filterCriteria.courseId, requestDate, onRequestDateChange]);

  /**
   * @brief creates a sorted attendance object
   * @param {*} attendance
   * @returns
   */
  const creatSortedAttendanceObject = (attendance, dayToDateMap) => {
    console.log(attendance);
    console.log(dayToDateMap);
    const sortedAttendance = {};

    const isoAttendance = {};
    Object.keys(attendance).forEach((key) => {
      const isoDate = moment(key, "DD-MM-YYYY").format("YYYY-MM-DD");
      isoAttendance[isoDate] = attendance[key];
    });

    Object.keys(dayToDateMap).forEach((day) => {
      const date = dayToDateMap[day];
      if (isoAttendance.hasOwnProperty(date)) {
        sortedAttendance[day] = isoAttendance[date];
      } else {
        sortedAttendance[day] = 4;
      }
    });

    return sortedAttendance;
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;



  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    // add rows to the dependencies array to ensure that visiblerows is recalculated when rows changes
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            filterCriteria={filterCriteria}
            setSelected={setSelected}
            selected={selected}
            requestDate={requestDate}
            setRequestDate={setRequestDate}
          />
          <TableContainer>
            <Table
              sx={{ minWidth: 600 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>

                      <TableCell align="center">{row.lastName}</TableCell>
                      <TableCell align="center">{row.firstName}</TableCell>
                      {Object.entries(row.attendance).map(
                        ([date, status], index) => (
                          <TableCell key={index} align="center">
                            <span
                              style={{
                                backgroundColor:
                                  status === 0
                                    ? "#E2001A"
                                    : status === 1
                                    ? "#4caf50"
                                    : status === 2
                                    ? "#efb100"
                                    : status === 3
                                    ? "#888888"
                                    : "#000",
                                color: "#fff",
                                padding: "5px 10px",
                                borderRadius: "25px",
                              }}
                            >
                              {status === 0
                                ? "Abwesend"
                                : status === 1
                                ? "Anwesend"
                                : status === 2
                                ? "Entschuldigt"
                                : status === 3
                                ? "Vorlesungsfrei"
                                : "Unbekannt"}
                            </span>
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <Button
        variant="contained"
        color="error"
        style={{ width: "15%", marginBottom: "25px" }} // Hier kannst du die Breite in Prozent ändern
        onClick={() => exportPdf()}
      >
        Tabelle exportieren
      </Button>
    </>
  );
}
