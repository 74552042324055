import React from "react";
import Footer from "../../components/footer";
import Navbar from "../../components/header";
import OptionGrid from "./components/optionGrid";
import styled from "styled-components";


/**
 * Renders the AccountsPage component.
 * @returns {JSX.Element} The rendered AccountsPage component.
 */
const AccountsPage = () => {
  return (
    <div>
      <Navbar />
      <ACCdiv>
      <OptionGrid />
      </ACCdiv>
      <Footer />
    </div>
  );
};

const ACCdiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 5%; /* Default padding */

  @media screen and (min-width: 800px) {
    padding: 0 10%; /* Add 10% padding on screens larger than 768px */
  }
`;

export default AccountsPage;
