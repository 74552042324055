import React from 'react';
import Navbar from '../../components/header';
import Footer from '../../components/footer';
import ScannerOverviewTable from './components/scannerOverview';
import styled from 'styled-components';

/**
 * Renders the Scanner Overview page.
 * @returns {JSX.Element} The Scanner Overview page component.
 */
const ScannerOverviewPage = () => {
    return (
      <div>
        <Navbar />
        <Scandiv>
        <ScannerOverviewTable />
        </Scandiv>
        <Footer />
      </div>
    );
  };

  const Scandiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 5%; /* Default padding */

  @media screen and (min-width: 800px) {
    padding: 0 10%; /* Add 10% padding on screens larger than 768px */
  }
`;

  export default ScannerOverviewPage;