import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "@mui/material";

import { useAuth } from "../../../../auth/AuthContext";
import FacultySelection from "./facultySelection";
import PostRequest from "../../../../components/postFormula";
import SuccessDialog from "../../../../components/successDialog";
import ErrorDialog from "../../../../components/errorDialog";
import ConfirmDialog from "../../../../components/confirmDialog";


/**
 * CourseDetails component displays a form for adding course details.
 * It includes input fields for name, short name, Rapla host, Rapla user, Rapla file,
 * and faculty selection (if the user is an admin).
 * It also includes a submit button for adding the course, and dialog windows for confirmation,
 * success message, and error message.
 */
const CourseDetails = () => {
  const { isAdmin, facultyId } = useAuth();
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const [raplaHost, setRaplaHost] = useState("");
  const [raplaUser, setRaplaUser] = useState("");
  const [raplaFile, setRaplaFile] = useState("");
  const [selectedfacultyId, setSelectedFacultyId] = useState(facultyId);
  const [openDialog, setOpenDialog] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [, setResetSelectedFaculty] = useState(false);

  /**
   * Handles the submit button click event.
   * @returns {Promise<void>}
   */
  const handleSubmitButton = async () => {
    setOpenDialog(true);
  };

  /**
   * Closes the dialog.
   */
  const handleClose = () => {
    setOpenDialog(false);
  };

  /**
   * @brief called when the user closes the success dialog
   */
  const handleCloseSuccessDialog = () => {
    window.location.reload();
    setSuccessDialogOpen(false);
  };

  /**
   * @brief called when the user closes the error dialog
   */
  const handleCloseErrorDialog = () => {
    window.location.reload();
    setErrorDialogOpen(false);
  };

  /**
   * @brief resets faculty selection in parent component
   */
  const resetFacultySelection = () => {
    setResetSelectedFaculty(true);
    setTimeout(() => {
      setResetSelectedFaculty(false);
    }, 100);
  };

  /**
   * @brief handles the confirmation of the dialog and sends the post request for adding a course
   */
  const handleConfirm = async () => {
    setOpenDialog(false);

    const courseData = {
      name,
      shortName,
      raplaHost,
      raplaUser,
      raplaFile,
      facultyId: selectedfacultyId,
    };

    console.log("Course added:", courseData);

    try {
      const response = await PostRequest({
        data: courseData,
        path: "courses",
      });
      console.log("Status", response.status);
      if (response.status === 201) {
        setName("");
        setShortName("");
        setRaplaHost("");
        setRaplaUser("");
        setRaplaFile("");
        setSuccessDialogOpen(true);
      } else {
        setErrorDialogOpen(true);
      }
    } catch (error) {
      console.log(error);
      setErrorDialogOpen(true);
    }
  };

  // return
  return (
    <>
      {/* Name Container */}
      <Container>
        <label htmlFor="name" style={labelStyle}>
          Name:
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          required
          style={inputStyle}
        />
      </Container>
      {/* ShortName Container */}
      <Container>
        <label htmlFor="shortName" style={labelStyle}>
          Kurzname:
        </label>
        <input
          type="text"
          id="shortName"
          value={shortName}
          onChange={(e) => {
            setShortName(e.target.value);
          }}
          required
          style={inputStyle}
        />
      </Container>
      {/* RaplaHost Container */}
      <Container>
        <label htmlFor="raplaHost" style={labelStyle}>
          Rapla Host:
        </label>
        <input
          type="text"
          id="raplaHost"
          value={raplaHost}
          onChange={(e) => {
            setRaplaHost(e.target.value);
          }}
          required
          style={inputStyle}
        />
      </Container>
      {/* RaplaUser Container */}
      <Container>
        <label htmlFor="raplaUser" style={labelStyle}>
          Rapla Benutzer:
        </label>
        <input
          type="text"
          id="raplaUser"
          value={raplaUser}
          onChange={(e) => {
            setRaplaUser(e.target.value);
          }}
          required
          style={inputStyle}
        />
      </Container>
      {/* RaplaFile Container */}
      <Container>
        <label htmlFor="raplaFile" style={labelStyle}>
          Rapla Datei:
        </label>
        <input
          type="text"
          id="raplaFile"
          value={raplaFile}
          onChange={(e) => {
            setRaplaFile(e.target.value);
          }}
          required
          style={inputStyle}
        />
      </Container>
      {/* FacultyId Container */}
      {isAdmin && (
        <FacultySelection
          onSelect={(value) => setSelectedFacultyId(value)}
          resetFacultySelection={resetFacultySelection}
        />
      )}
      {/* submit button */}
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={handleSubmitButton}
        style={{
          backgroundColor: "#E2001A",
          marginTop: "20px",
        }}
        disabled={
          !(
            name &&
            shortName &&
            raplaHost &&
            raplaUser &&
            raplaFile &&
            (isAdmin ? selectedfacultyId : true)
          )
        }
      >
        Kurs hinzufügen
      </Button>

      {/* confirmation window */}
      <ConfirmDialog
        open={openDialog}
        onClose={handleClose}
        onConfirm={handleConfirm}
        confirmText="Möchten Sie diesen Kurs erstellen?"
        dialogTitle="Kurs erstellen"
      />
      {/* success pop up */}
      <SuccessDialog
        open={successDialogOpen}
        onClose={handleCloseSuccessDialog}
        text="Kurs wurde erfolgreich erstellt."
      />
      {/* error dialog */}
      <ErrorDialog
        open={errorDialogOpen}
        onClose={handleCloseErrorDialog}
        errorMessage="Kurs konnte nicht erstellt werden."
      />
    </>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const labelStyle = {
  marginRight: "10px",
  width: "140px",
  minWidth: "140px",
};

const inputStyle = {
  border: "1px solid #E2001A",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  fontSize: "20px",
  padding: "8px 12px",
  borderRadius: "4px",
  flex: 1,
};

export default CourseDetails;
