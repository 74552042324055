import React, {useState } from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import SuccessDialog from "../../../../components/successDialog";
import ErrorDialog from "../../../../components/errorDialog";
import ConfirmDialog from "../../../../components/confirmDialog";
import PostRequest from "../../../../components/postFormula";
import { Checkbox } from "@mui/material";

/**
 * Represents the FacultyDetails component.
 * This component is responsible for rendering the form for adding faculty details.
 *
 * @component
 */
/**
 * Represents the FacultyDetails component.
 * This component is responsible for rendering the form for adding faculty details.
 *
 * @component
 * @example
 * return (
 *   <FacultyDetails />
 * )
 */
const FacultyDetails = () => {
  const [name, setName] = useState("");
  const [startCheckInOffset, setStartCheckInOffset] = useState(0);
  const [endCheckInOffset, setEndCheckInOffset] = useState(0);
  const [minimumCheckinTime, setMinimumCheckInTime] = useState(0);
  const [onlyFirstLesson, setOnlyFirstLesson] = useState(false);
  const [twoFactorType, setTwoFactorType] = useState("NONE");
  const [twoFactorTime, setTwoFactorTime] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  /**
   * Handles the submit button click event.
   * @returns {Promise<void>}
   */
  const handleSubmitButton = async () => {
    setOpenDialog(true);
  };

  /**
   * Closes the dialog.
   */
  const handleClose = () => {
    setOpenDialog(false);
  };

  /**
   * Closes the success dialog.
   */
  const handleCloseSuccessDialog = () => {
    setSuccessDialogOpen(false);
  };

  /**
   * Closes the error dialog.
   */
  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  /**
   * @brief handles the confirmation of the dialog and sends the post request for adding a faculty
   */
  /**
   * Handles the confirmation of faculty details.
   * @returns {Promise<void>} A promise that resolves when the confirmation is handled.
   */
  const handleConfirm = async () => {
    setOpenDialog(false);
    console.log(typeof startCheckInOffset);
    // parse input values to numbers
    const parsedStartCheckInOffset = Number(startCheckInOffset);
    const parsedEndCheckInOffset = Number(endCheckInOffset);
    const parsedMinimumCheckinTime = Number(minimumCheckinTime);
    const parsedTwoFactorTime = Number(twoFactorTime);

    console.log(typeof parsedStartCheckInOffset);

    const facultyData = {
      name,
      startCheckInOffset: parsedStartCheckInOffset,
      endCheckInOffset: parsedEndCheckInOffset,
      minimumCheckinTime: parsedMinimumCheckinTime,
      onlyFirstLesson,
      twoFactorType,
      twoFactorTime: parsedTwoFactorTime,
    };
    
    try {
      const response = await PostRequest({
        data: facultyData,
        path: "faculties",
      });

      if (response.status === 201) {
        setName("");
        setStartCheckInOffset(0);
        setEndCheckInOffset(0);
        setMinimumCheckInTime(0);
        setOnlyFirstLesson(false);
        setTwoFactorType("OFF");
        setTwoFactorTime(0);
        setSuccessDialogOpen(true);
      } else {
        setErrorDialogOpen(true);
      }
    } catch (error) {
      console.error(error);
      setErrorDialogOpen(true);
    }
  };

  /**
   * Handles the change event for number input fields.
   *
   * @param {Function} setStateFunc - The state setter function.
   * @param {number} value - The new value for the input field.
   */
  const handleNumberInputChange = (setStateFunc, value) => {
    setStateFunc(value);
  };

  return (
    <>
      {/* name container */}
      <Container>
        <label htmlFor="name" style={labelStyle}>
          Name:
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          style={inputStyle}
        />
      </Container>

      {/* start checkin Offset Container */}
      <Container>
        <label htmlFor="startCheckInOffset" style={labelStyle}>
          Start CheckIn Offset:
        </label>
        <input
          type="number"
          id="startCheckInOffset"
          value={startCheckInOffset}
          onChange={(e) => setStartCheckInOffset(Number(e.target.value))}
          required
          style={inputStyle}
        />
      </Container>

      {/* end checkIn Offset container */}
      <Container>
        <label htmlFor="endCheckInOffset" style={labelStyle}>
          End CheckIn Offset:
        </label>
        <input
          type="number"
          id="endCheckInOffset"
          value={endCheckInOffset}
          onChange={(e) => setEndCheckInOffset(e.target.value)}
          required
          style={inputStyle}
        />
      </Container>

      {/* minimum CheckIn Time Container */}
      <Container>
        <label htmlFor="minimumCheckinTime" style={labelStyle}>
          Minimale Check In Zeit:
        </label>
        <input
          type="number"
          id="minimumCheckinTime"
          value={minimumCheckinTime}
          onChange={(e) => setMinimumCheckInTime(e.target.value)}
          required
          style={inputStyle}
        />
      </Container>

      {/* onlyFirstLesson Container */}
      <label htmlFor="onlyFirstLesson" style={labelStyle}>
        Nur erste Stunde CheckIn:
      </label>
      <Checkbox
        id="onlyFirstLesson"
        checked={onlyFirstLesson}
        onChange={(e) => setOnlyFirstLesson(e.target.checked)}
      />

      {/* twoFactorType Container */}
      <Container>
        <label htmlFor="twoFactorType" style={labelStyle}>
          Zweifaktor-Authentifizierung:
        </label>
        <select
          id="twoFactorType"
          value={twoFactorType}
          onChange={(e) => setTwoFactorType(e.target.value)}
          style={inputStyle}
        >
          <option value="NONE">Keine</option>
          <option value="CODE">CODE</option>
        </select>
      </Container>

      {/* twoFactorTime Container */}
      <Container>
        <label htmlFor="twoFactorTime" style={labelStyle}>
          Zweifaktor-Zeit:
        </label>
        <input
          type="number"
          id="twoFactorTime"
          value={twoFactorTime}
          onChange={(e) =>
            handleNumberInputChange(setTwoFactorTime, parseInt(e.target.value))
          }
          required={twoFactorType !== "OFF"}
          style={inputStyle}
          disabled={twoFactorType === "OFF"}
        />
      </Container>

      {/* submit button */}
      {name &&
        startCheckInOffset !== undefined &&
        endCheckInOffset !== undefined &&
        minimumCheckinTime !== undefined &&
        twoFactorTime !== undefined && (
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleSubmitButton}
            style={{
              backgroundColor: "#E2001A",
              marginTop: "20px",
            }}
          >
            Fakultät hinzufügen
          </Button>
        )}

      {/* confirmation dialog */}
      <ConfirmDialog
        open={openDialog}
        onClose={handleClose}
        onConfirm={handleConfirm}
        confirmText="Möchten Sie diese Fakultät hinzufügen?"
        dialogTitle="Fakultät hinzufügen"
      />
      {/* success dialog */}
      <SuccessDialog
        open={successDialogOpen}
        onClose={handleCloseSuccessDialog}
        text="Fakultät wurde erfolgreich hinzugefügt."
      />
      {/* error dialog */}
      <ErrorDialog
        open={errorDialogOpen}
        onClose={handleCloseErrorDialog}
        errorMessage="Fakultät konnte nicht hinzugefügt werden."
      />
    </>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const labelStyle = {
  marginRight: "10px",
  width: "140px",
  minWidth: "140px",
};

const inputStyle = {
  border: "1px solid #E2001A",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  fontSize: "20px",
  padding: "8px 12px",
  borderRadius: "4px",
  flex: 1,
};

export default FacultyDetails;
