import React from "react";
import { Dialog, DialogContent, DialogContentText, DialogTitle, Button, DialogActions } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

/**
 * SuccessDialog component displays a dialog with a success message and an OK button.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Determines whether the dialog is open or not.
 * @param {function} props.onClose - Callback function to handle the dialog close event.
 * @param {string} props.text - The success message to be displayed in the dialog.
 * @returns {JSX.Element} - The rendered SuccessDialog component.
 */
const SuccessDialog = ({ open, onClose, text }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Erfolgreich!</DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CheckCircleIcon
            style={{
              color: "#4CAF50",
              fontSize: "50px",
              marginRight: "10px",
            }}
          />
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;
