import React, { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "styled-components";
import GetRequest from "../../../../components/getFormular";
import ConfirmDialog from "../../../../components/confirmDialog";
import ErrorDialog from "../../../../components/errorDialog";
import SuccessDialog from "../../../../components/successDialog";
import DeleteRequest from "../../../../components/deleteFormula";

/**
 * @brief filter faculties and deletes selected faculty
 * @returns {JSX.Element}
 */
const FacultyFilter = () => {
  const [selectedFaculty, setSelectedFaculty] = useState("");
  const [facultyIds, setFacultyIds] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [faculties, setFaculties] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  useEffect(() => {
    /**
     * Fetches the list of faculties from the server.
     * @returns {Promise<void>} A Promise that resolves when the faculties are fetched successfully.
     */
    const fetchFaculties = async () => {
      try {
        const response = await GetRequest("faculties");
        const facultiesData = response.data;
        const facultiesNames = facultiesData.map((faculty) => faculty.name);
        const facultiesMap = facultiesData.reduce((acc, faculty) => {
          acc[faculty.name] = faculty.id;
          return acc;
        }, {});

        setFaculties(facultiesNames);
        setFacultyIds(facultiesMap);
      } catch (error) {
        console.error(error);
      }
    };
    fetchFaculties();
  }, []);

  const handleSubmitButton = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseSuccessDialog = () => {
    setSuccessDialogOpen(false);
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  /**
   * Handles the confirmation of deleting a faculty.
   * Closes the dialog, resets the selected faculty, and sends a delete request to the server.
   * If the delete request is successful, opens the success dialog.
   * If the delete request fails, opens the error dialog.
   * If an error occurs during the delete request, logs the error to the console.
   */
  const handleConfirm = async () => {
    setOpenDialog(false);
    setSelectedFaculty("");
    try {
      const response = await DeleteRequest(
        `faculties/${facultyIds[selectedFaculty]}`
      );
      if (response.status === 200) {
        setSuccessDialogOpen(true);
        setSelectedFaculty("");
      } else {
        setErrorDialogOpen(true);
      }
    } catch (error) {
      setErrorDialogOpen(true);
      console.error("Error:", error);
    }
  };

  /**
   * Handles the selection of a faculty.
   *
   * @param {Object} faculty - The selected faculty object.
   */
  const handleFacultySelect = (faculty) => {
    setSelectedFaculty(faculty);
    setAnchorEl(null);
  };

  return (
    <div>
      <Container>
        <label htmlFor="faculties" style={labelStyle}>
          Fakultät:{" "}
        </label>
        <Button
          id="faculty-button"
          aria-label="Fakultäten"
          aria-controls="faculty-menu"
          aria-haspopup="true"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<KeyboardArrowDownIcon />}
          style={buttonStyle}
        >
          {selectedFaculty || "Fakultät"}
        </Button>
        <Menu
          id="faculty-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {faculties.map((faculty) => (
            <MenuItem
              key={faculty}
              onClick={() => handleFacultySelect(faculty)}
            >
              {faculty}
            </MenuItem>
          ))}
        </Menu>
      </Container>
      <Container>
        {selectedFaculty ? (
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleSubmitButton}
            style={{
              backgroundColor: "#E2001A",
              marginTop: "20px",
            }}
          >
            Fakultät löschen
          </Button>
        ) : null}
      </Container>


            {/* confirmation dialog */}
      <ConfirmDialog
        open={openDialog}
        onClose={handleClose}
        onConfirm={handleConfirm}
        confirmText="Möchten Sie diese Fakultät löschen?"
        dialogTitle="Fakultät löschen"
      />
      {/* success dialog */}
      <SuccessDialog
        open={successDialogOpen}
        onClose={handleCloseSuccessDialog}
        text="Fakultät wurde erfolgreich gelöscht"
      />
      {/* error dialog */}
      <ErrorDialog
        open={errorDialogOpen}
        onClose={handleCloseErrorDialog}
        errorMessage="Fakultät konnte nicht gelöscht werden."
      />
    </div>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const labelStyle = {
  marginRight: "10px",
  width: "140px",
  minWidth: "140px",
};

const buttonStyle = {
  color: "black",
  border: "1px solid #E2001A",
  fontFamily: "Arial, sans-serif",
  textTransform: "none",
  fontSize: "20px",
};

export default FacultyFilter;
