import axios from "axios";

const BASE_URL = `https://api.studicheckin.afink.dev/`;

/**
 * Sends a PATCH request to the specified path with the provided data.
 * @param {Object} options - The options for the PATCH request.
 * @param {Object} options.data - The data to be sent in the request body.
 * @param {string} options.path - The path to send the PATCH request to.
 * @returns {Promise<Object>} - A promise that resolves to the response data.
 * @throws {Error} - If an error occurs while sending the PATCH request.
 */
const PatchRequest = async ({ data, path }) => {
  try {
    console.log("Sending PATCH request...");
    const response = await axios.patch(`${BASE_URL}${path}`, data);
    console.log("PATCH request was successful!");
    return response; // Erfolgreiche Antwort zurückgeben
  } catch (error) {
    console.error("Error while sending PATCH request:", error);
    throw new Error("Fehler beim Senden des PATCH-Anforderung");
  }
};

export default PatchRequest;
