import React, { useState } from "react";
import styled from "styled-components";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuButton from "../../../components/menu";
import AddAccountForm from "./addAccount/addAccountForm";
import DeleteAccountForm from "./deleteAccount/deleteAccountForm";
import AddCoursesForm from "./addCourses/addCoursesForm";
import EnhancedTable from "../overviewManager/overviewManagerTable";
import DeleteCoursesFrom from "./deleteCourses/deleteCoursesForm";

import { useAuth } from "../../../auth/AuthContext";

const OptionGrid = () => {
  const [isAddingAccount, setIsAddingAccount] = useState(false);
  const [isDeletingAccount, setIsDeletingAccount] = useState(false);
  const [isOverview, setIsOverview] = useState(false);
  const [isNewCourses, setIsNewCourses] = useState(false);
  const [isDeletingCourses, setIsDeletingCourses] = useState(false);
  const { isAdmin } = useAuth();

  /**
   * Handles the account action based on the provided action parameter.
   *
   * @param {string} action - The action to be performed.
   */
  const handleAccountAction = (action) => {
    switch (action) {
      case "add":
        setIsAddingAccount(true);
        break;
      case "delete":
        setIsDeletingAccount(true);
        break;
      case "overview":
        setIsOverview(true);
        break;
      case "newCourses":
        setIsNewCourses(true);
        break;
      case "deleteCourses":
        setIsDeletingCourses(true);
        break;
      default:
        setIsAddingAccount(false);
    }
  };

  /**
   * Handles the back action.
   */
  const handleBack = () => {
    setIsAddingAccount(false);
    setIsDeletingAccount(false);
    setIsOverview(false);
    setIsNewCourses(false);
    setIsDeletingCourses(false);
  };

  return (
    <Main>
      <IconButton
        sx={{ position: "absolute", top: 16, right: 16 }}
        onClick={handleBack}
        style={{
          display:
            isAddingAccount ||
            isDeletingAccount ||
            isOverview ||
            isNewCourses ||
            isDeletingCourses
              ? "block"
              : "none",
        }}
      >
        <ArrowBackIcon />
      </IconButton>

      <MenuButton />
      {isAddingAccount ? (
        <AddAccountForm />
      ) : isDeletingAccount ? (
        <DeleteAccountForm />
      ) : isOverview ? (
        <EnhancedTable />
      ) : isNewCourses ? (
        <AddCoursesForm />
      ) : isDeletingCourses ? (
        <DeleteCoursesFrom />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom style={{ marginTop: "20px" }}>
              Accountverwaltung
            </Typography>
            <Typography variant="body1" gutterBottom>
              Hier erhalten Sie eine Übersicht über alle registrierten Accounts
              und die Möglichkeit diese zu bearbeiten.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card style={{ height: "100%" }}>
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <Typography variant="h6" gutterBottom fontWeight="normal">
                  Neue Konten hinzufügen:
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  color="error"
                  style={{ width: "100%", marginBottom: "25px" }}
                  onClick={() => handleAccountAction("add")}
                >
                  Account hinzufügen
                </Button>
              </CardContent>
            </Card>
          </Grid>
          {isAdmin && (
            <Grid item xs={12} md={6}>
              <Card style={{ height: "100%" }}>
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <Typography variant="h6" gutterBottom fontWeight="normal">
                    Übersicht aller Verwalter Konten:
                  </Typography>
                  <Button
                    fullWidth
                    variant="contained"
                    color="error"
                    style={{ width: "100%", marginBottom: "25px" }}
                    onClick={() => handleAccountAction("overview")}
                  >
                    Zur Übersicht
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          )}
          {/* New card for creating new courses/faculties */}
          <Grid item xs={12} md={6}>
            <Card style={{ height: "100%" }}>
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                {isAdmin ? (
                  <Typography variant="h6" gutterBottom fontWeight="normal">
                    Neue Kurse/Fakultäten anlegen:
                  </Typography>
                ) : (
                  <Typography variant="h6" gutterBottom fontWeight="normal">
                    Neue Kurse anlegen:
                  </Typography>
                )}

                <Button
                  fullWidth
                  variant="contained"
                  color="error"
                  style={{ width: "100%", marginBottom: "25px" }}
                  onClick={() => handleAccountAction("newCourses")}
                >
                  {isAdmin ? "Neue Kurse/Fakultäten anlegen" : "Kurse anlegen"}
                </Button>
              </CardContent>
            </Card>
          </Grid>
          {/* deleting card for courses/faculties */}
          <Grid item xs={12} md={6}>
            <Card style={{ height: "100%" }}>
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                {isAdmin ? (
                  <Typography variant="h6" gutterBottom fontWeight="normal">
                    Kurse/Fakultäten löschen:
                  </Typography>
                ) : (
                  <Typography variant="h6" gutterBottom fontWeight="normal">
                    Kurse löschen:
                  </Typography>
                )}

                <Button
                  fullWidth
                  variant="contained"
                  color="error"
                  style={{ width: "100%", marginBottom: "25px" }}
                  onClick={() => handleAccountAction("deleteCourses")}
                >
                  {isAdmin ? "Kurse/Fakultäten löschen" : "Kurse löschen"}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 100px;
  min-height: 72vh;
  position: relative;
`;

export default OptionGrid;
