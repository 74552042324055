import Footer from "../../components/footer";
import Navbar from "../../components/header";
import EventMenu from "./components/EventMenu";

/**
 * Renders the EventMenuPage component.
 * @returns {JSX.Element} The rendered EventMenuPage component.
 */
const EventMenuPage = () => {
  return (
    <div>
      <Navbar />
        <EventMenu />
      <Footer />
    </div>
  );
};


export default EventMenuPage;
