import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PatchRequest from "../../../components/patchFormula";

/**
 * Renders the EventMenu component.
 * This component displays event details and allows the user to update certain properties.
 *
 * @returns {JSX.Element} The rendered EventMenu component.
 */
const EventMenu = () => {
  const eventTitle = localStorage.getItem('eventTitle');
  const eventId = localStorage.getItem('eventId');
  const eventCourses = localStorage.getItem('eventCourses');
  const eventroom = localStorage.getItem('eventRoom');

  /**
   * Converts a date string to a German-formatted date string.
   * @param {string} datumString - The date string to convert.
   * @returns {string} The German-formatted date string.
   */
  function konvertiereDatum(datumString) {
    const wochentage = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
    const monate = ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];

    const datum = new Date(datumString);
    const wochentagIndex = datum.getDay();
    const tag = datum.getDate();
    const monatIndex = datum.getMonth();
    const jahr = datum.getFullYear();
    const stunden = ('0' + datum.getHours()).slice(-2);
    const minuten = ('0' + datum.getMinutes()).slice(-2);

    const deutschesDatum = `${wochentage[wochentagIndex]}, ${tag} ${monate[monatIndex]} ${jahr}, ${stunden}:${minuten}`;
    return deutschesDatum;
  }

  const Kon_eventStartTime = konvertiereDatum(localStorage.getItem('eventstart'));
  const Kon_eventEndTime = konvertiereDatum(localStorage.getItem('eventend'));

  const [forceCheckIn, setForceCheckIn] = useState(localStorage.getItem('eventForceCheckIn') === 'true');
  const [checkBoxChanged, setCheckBoxChanged] = useState(false);
  const [iscanceledState, setIscanceledState] = useState(localStorage.getItem('eventIsCanceled') === 'true');
  const [isonlineState, setIsonlineState] = useState(localStorage.getItem('eventIsOnline') === 'true');
  const [isoptionalState, setIsoptionalState] = useState(localStorage.getItem('eventIsOptional') === 'true');

  /**
   * Handles the change event for the force check-in checkbox.
   * @param {Object} event - The event object.
   */
  const handleForceCheckInChange = (event) => {
    const newValue = event.target.checked;
    setForceCheckIn(newValue);
    updateStateAndSendRequest({ "forceCheckIn": newValue });
    localStorage.setItem('eventForceCheckIn', newValue);
    setCheckBoxChanged(true);
  };

  /**
   * Handles the change event for the "isCanceled" checkbox.
   * Updates the state, sends a request, and updates the local storage.
   *
   * @param {Event} event - The change event object.
   * @returns {void}
   */
  const handleIscanceledChange = (event) => {
    const newValue = event.target.checked;
    setIscanceledState(newValue);
    updateStateAndSendRequest({ "isCanceled": newValue });
    localStorage.setItem('eventIsCanceled', newValue);
    setCheckBoxChanged(true);
  };

  /**
   * Handles the change event for the "isOnline" checkbox.
   * Updates the state, sends a request, and updates the local storage.
   *
   * @param {Event} event - The change event object.
   * @returns {void}
   */
  const handleIsonlineChange = (event) => {
    const newValue = event.target.checked;
    setIsonlineState(newValue);
    updateStateAndSendRequest({ "isOnline": newValue });
    localStorage.setItem('eventIsOnline', newValue);
    setCheckBoxChanged(true);
  };

  /**
   * Handles the change event for the "isOptional" checkbox.
   * Updates the state, sends a request, and updates the local storage.
   *
   * @param {Event} event - The change event object.
   * @returns {void}
   */
  const handleIsoptionalChange = (event) => {
    const newValue = event.target.checked;
    setIsoptionalState(newValue);
    updateStateAndSendRequest({ "isOptional": newValue });
    localStorage.setItem('eventIsOptional', newValue);
    setCheckBoxChanged(true);
  };

  /**
   * Updates the state and sends a PATCH request to update the event properties.
   *
   * @param {Object} dataintern - The data to be sent in the PATCH request.
   * @returns {void}
   */
  const updateStateAndSendRequest = (dataintern) => {
    const data = dataintern;
    const path = `lessons/${eventId}`;
    PatchRequest({ data, path })
      .then((responseData) => {
        console.log("Response from PATCH request:", responseData);
      })
      .catch((error) => {
        console.error("Error from PATCH request:", error);
      });
  };

  useEffect(() => {
    if (checkBoxChanged) {
      console.log("jetzt neu laden");
      const otherTabWindow = window.opener;
      if (otherTabWindow) {
        otherTabWindow.location.reload();
      } else {
        console.error('Kein Zugriff auf das Fensterobjekt des anderen Tabs möglich.');
      }
      setCheckBoxChanged(false);
    }
  }, [checkBoxChanged]);

  return (
    <div>
      <Grid>
        <Title>{eventTitle}</Title>
        <Box>
          <h2>Startzeit</h2>
          <p>{Kon_eventStartTime}</p>
        </Box>
        <Box>
          <h2>Endzeit</h2>
          <p>{Kon_eventEndTime}</p>
        </Box>
        <Box>
          <h2>Zusätzlich Einchecken</h2>
          <p>
            <input type="checkbox" checked={forceCheckIn} onChange={handleForceCheckInChange} />
          </p>
        </Box>
        <Box>
          <h2>Kurse</h2>
          <p>{eventCourses}</p>
        </Box>
        <Box>
          <h2>Raum</h2>
          <p>{eventroom}</p>
        </Box>
        <Box>
          <h2>Abgesagt</h2>
          <p>
            <input type="checkbox" checked={iscanceledState} onChange={handleIscanceledChange} />
          </p>
        </Box>
        <Box>
          <h2>Online</h2>
          <p>
            <input type="checkbox" checked={isonlineState} onChange={handleIsonlineChange} />
          </p>
        </Box>
        <Box>
          <h2>Optional</h2>
          <p>
            <input type="checkbox" checked={isoptionalState} onChange={handleIsoptionalChange} />
          </p>
        </Box>
      </Grid>
    </div>
  );
};

const Grid = styled.div`
  padding: 0% 15%;
`;

const Title = styled.div`
  width: 100%;
  font-size: 48px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  padding-bottom: 20px;
  padding-top: 20px;
`;

const Box = styled.div`
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 10px;
`;

export default EventMenu;
