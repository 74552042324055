import React, {useState } from "react";
import styled from "styled-components";
import SuccessDialog from "../../../../components/successDialog";
import ErrorDialog from "../../../../components/errorDialog";
import ConfirmDialog from "../../../../components/confirmDialog";

import { Button } from "@mui/material";
import CourseFilter from "./courseSelection";
import PostRequest from "../../../../components/postFormula";


/**
 * Represents the account details form for a student.
 *
 * @component
 * @param {function} handleResetSelectedRole - The function to handle the reset of the selected role.
 * @returns {JSX.Element} The account details form for a student.
 */
const AccountDetailsStudent = ({ handleResetSelectedRole }) => {
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [regNumber, setRegNumber] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [courseId, setCourseId] = useState("");
  const [resetSelectedCourse, setResetSelectedCourse] = useState(false);

  /**
   * @brief handles the reset of the selected course
   */
  const handleResetSelectedCourse = () => {
    setResetSelectedCourse(true);
    setTimeout(() => {
      setResetSelectedCourse(false);
    }, 100);
  };

  /**
   * @brief handles the submission of the add student form
   */
  const handleSubmitButton = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  /**
   * @brief called when the user closes the success or dialog
   */
  const handleCloseSuccessDialog = () => {
    setSuccessDialogOpen(false);
  };

  /**
   * @brief called when the user closes the error dialog
   */
  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  /**
   * @brief handles the confirmation of the  edit dialog and sends the post request for adding a student
   */
  const handleConfirm = async () => {
    setOpenDialog(false);
    const studentData = {
      email,
      lastName,
      firstName,
      regNumber,
      courseId,
    };
    try {
      const response = await PostRequest({
        data: studentData,
        path: "students",
      });

      if (response.status === 201) {
        // reset data
        setEmail("");
        setLastName("");
        setFirstName("");
        setRegNumber("");
        setCourseId("");
        // reset dropdown menus
        handleResetSelectedCourse();
        handleResetSelectedRole();

        setSuccessDialogOpen(true);
      } else {
        setErrorDialogOpen(true); // Anzeigen des Fehler-Popups
        console.error("Error:", response.status);
      }
    } catch (error) {
      setErrorDialogOpen(true); // Anzeigen des Fehler-Popups
      console.error("Error:", error);
    }
  };

  return (
    <>
      {/* e-mail Container */}
      <Container>
        <label htmlFor="email" style={labelStyle}>
          E-Mail:
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          style={inputStyle}
        />
      </Container>
      {/* lastName Container */}
      <Container>
        <label htmlFor="name" style={labelStyle}>
          Name:
        </label>
        <input
          type="text"
          id="name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
          style={inputStyle}
        />
      </Container>
      {/* firstName Container */}
      <Container>
        <label htmlFor="firstName" style={labelStyle}>
          Vorname:
        </label>
        <input
          type="text"
          id="firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
          style={inputStyle}
        />
      </Container>
      {/* Matrikelnummer Container */}
      <Container>
        <label htmlFor="matrikelNummer" style={labelStyle}>
          Matrikelnummer:
        </label>
        <input
          type="number"
          id="matrikelNummer"
          value={regNumber}
          onChange={(e) => setRegNumber(e.target.value.slice(0, 7))}
          required
          maxLength={7} // since the matrikel number is 7 digits long
          style={inputStyle}
        />
      </Container>

      {/* course container */}
      <CourseFilter
        onSelect={(value) => setCourseId(value)}
        resetSelectedCourse={resetSelectedCourse}
      />

      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={handleSubmitButton}
        style={{
          backgroundColor: "#E2001A",
          marginTop: "20px",
        }}
        disabled={
          !email ||
          !lastName ||
          !firstName ||
          !courseId ||
          regNumber.length !== 7
        }
      >
        Student hinzufügen
      </Button>

      {/* Dialogwindow for confirmation*/}
      <ConfirmDialog
        open={openDialog}
        onClose={handleClose}
        onConfirm={handleConfirm}
        confirmText="Möchten Sie diesen Student hinzufügen?"
        dialogTitle="Student hinzufügen"
      />
      {/* success modal */}
      <SuccessDialog
        open={successDialogOpen}
        onClose={handleCloseSuccessDialog}
        text="Student wurde erfolgreich hinzugefügt."
      />
      {/* error modal */}
      <ErrorDialog
        open={errorDialogOpen}
        onClose={handleCloseErrorDialog}
        errorMessage="Student konnte nicht hinzugefügt werden. Überprüfen Sie, ob die E-Mail bereits existiert."
      />
    </>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const labelStyle = {
  marginRight: "10px",
  width: "140px",
  minWidth: "140px",
};

const inputStyle = {
  border: "1px solid #E2001A",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  fontSize: "20px",
  padding: "8px 12px",
  borderRadius: "4px",
  flex: 1,
};

export default AccountDetailsStudent;
