import React from 'react';
import Navbar from '../../components/header';
import Footer from '../../components/footer';
import CourseOverviewTable from './components/courseOverview';

/**
 * Renders the Course Overview page.
 * @returns {JSX.Element} The Course Overview page component.
 */
const CourseOverviewPage = () => {
    return (
      <div>
        <Navbar />
        <CourseOverviewTable />
        <Footer />
      </div>
    );
  };

  export default CourseOverviewPage;