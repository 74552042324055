import React from 'react';
import Navbar from '../../components/header';
import Footer from '../../components/footer';
import StudentOverviewTable from './components/studentOverview';
import styled from 'styled-components';

/**
 * Represents the Student Overview Page component.
 * This component displays the student overview page, including the navbar, student overview table, and footer.
 *
 * @returns {JSX.Element} The rendered Student Overview Page component.
 */
const StudentOverviewPage = () => {
    return (
      <div>
        <Navbar />
        <STUdiv>
        <StudentOverviewTable />
        </STUdiv>
        <Footer />
      </div>
    );
  };
  const STUdiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 5%; /* Default padding */

  @media screen and (min-width: 800px) {
    padding: 0 10%; /* Add 10% padding on screens larger than 768px */
  }
`;
  export default StudentOverviewPage;