import React from "react";
import Navbar from "../../components/header";
import Footer from "../../components/footer";
import AttendanceTable from "./components/attendanceTable";

/**
 * Renders the AttendancePage component.
 *
 * @returns {JSX.Element} The rendered AttendancePage component.
 */
const AttendancePage = () => {
  return (
    <div>
      <Navbar />
      <AttendanceTable />
      <Footer />
    </div>
  );
};

export default AttendancePage;
