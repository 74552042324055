import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import ErrorDialog from "../../../components/errorDialog";
import SuccessDialog from "../../../components/successDialog";
import DropdownSelection from "../../../components/staticRoleSelectionTemplate";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { DialogContentText } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import "moment/locale/de";
import PostRequest from "../../../components/postFormula";

import axios from "axios";
import PatchRequest from "../../../components/patchFormula";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Nachname",
  },
  {
    id: "Vorname",
    numeric: false,
    disablePadding: false,
    label: "Vorname",
  },
  {
    id: "Anwesenheit",
    numeric: false,
    disablePadding: false,
    label: "Anwesenheit",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, selected, setSelected, lessonName, lessonId } = props;
  const [open, setOpen] = React.useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
  const [selectedAttendanceStatus, setSelectedAttendanceStatus] =
    React.useState(false);

  /**
   * @brief called when the user selects an attendance status from the dropdown menu and confirms in the dialog
   */
  const createAttendance = async () => {
    try {
      for (const studentId of selected) {
        const requestData = {
          studentId: studentId,
          lessonId: lessonId,
        };

        const response = await PostRequest({
          data: requestData,
          path: `attendances`,
        });

        if (response.status === 201) {
          setSuccessDialogOpen(true);
        }
      }
    } catch (error) {
      console.log("Error:", error);
      setErrorDialogOpen(true);
    }
  };

  /**
   * @brief called when the user selects an attendance status from the dropdown menu and confirms in the dialog
   * deletes the attendance
   * @returns {Promise<void>}
   */
  const deleteAttendance = async () => {
    try {
      for (const studentId of selected) {
        const requestData = {
          isAttending: false
        };

        const response = await PatchRequest({
          data: requestData,
          path: `students/${studentId}/lesson/${lessonId}/attendance`,
        });

        if (response.status === 200) {
          setSuccessDialogOpen(true);
        }
      }
    } catch (error) {
      console.log("Error:", error);
      setErrorDialogOpen(true);
    }
  };

  const handleEdit = async () => {
    console.log(selected); // array of id from selected students
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelected([]);
  };

  const handleCloseSuccessDialog = () => {
    setSuccessDialogOpen(false);
    window.location.reload();
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
    window.location.reload();
  };

  /**
   * @brief called when the user selects an attendance status from the dropdown menu
   * @param {*} selectedAttendanceStatus
   */
  const handleAttendanceStatusSelection = (selectedAttendanceStatus) => {
    console.log("Selected Status:", selectedAttendanceStatus);

    switch (selectedAttendanceStatus) {
      case "Abwesend":
        setSelectedAttendanceStatus(false);
        break;
      case "Anwesend":
        setSelectedAttendanceStatus(true);
        break;
      default:
        setSelectedAttendanceStatus(false);
    }
  };

  const handleConfirm = async () => {
    setOpen(false);
    // check selected status
    console.log("Selected Status:", selectedAttendanceStatus);
    if (selectedAttendanceStatus) {
      createAttendance();
    } else {
      deleteAttendance();
    }
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%", fontWeight: "bold" }}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          Vorlesung - {lessonName}
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Edit">
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle>Anwesenheit bearbeiten</DialogTitle>
        <DialogContent>
          <>
            <DialogContentText style={{ paddingBottom: "16px" }}>
              Wählen Sie den Status für die Anwesenheit aus.
            </DialogContentText>
            <DialogContentText style={{ color: "red", paddingBottom: "16px" }}>
              ⚠ Achtung: Diese Änderung betrifft alle ausgewählten Studenten!
            </DialogContentText>

            <DropdownSelection
              options={["Anwesend", "Abwesend"]}
              onSelect={handleAttendanceStatusSelection}
              label={"Status"}
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={handleConfirm} color="primary">
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
      {/* success pop up */}
      <SuccessDialog
        open={successDialogOpen}
        onClose={handleCloseSuccessDialog}
        text="Aktion erfolgreich ausgeführt"
      />
      <ErrorDialog
        open={errorDialogOpen}
        onClose={handleCloseErrorDialog}
        errorMessage="Die gewünschte Aktion konnte nicht durchgeführt werden"
      />
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
};

/**
 * component that gets imported in parent component
 * uses Toolbar, Head components to include more functions
 * @returns {JSX.Element}
 */
export default function EnhancedTableCheckIn({
  rows,
  lessonName,
  filterCriteria,
  lessonId,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("lastName");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  /**
   * @brief exports the table to a pdf file
   */
  const exportPdf = async () => {
    console.log("exporting pdf");
    console.log(filterCriteria);
    try {
      const response = await axios.get(
        `https://api.studicheckin.afink.dev/courses/${filterCriteria.courseId}/attendance/pdf/lesson/${lessonId}`,
        {
          responseType: "blob",
        }
      );

      console.log("Response:", response.headers);

      const contentDispositionHeader = response.headers["content-disposition"];
      let filename = "attendance.pdf";

      if (contentDispositionHeader) {
        filename = contentDispositionHeader
          .split(";")
          .find((n) => n.includes("filename="))
          .replace("filename=", "")
          .trim();
        console.log("Filename:", filename);
      } else {
        console.log("Content-Disposition header not found in response.");
      }

      const url = window.URL.createObjectURL(response.data);

      const a = document.createElement("a");
      a.href = url;
      a.download = filename; // Verwenden Sie den extrahierten Dateinamen
      a.style.display = "none";
      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    // add rows to the dependencies array to ensure that visiblerows is recalculated when rows changes
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            selected={selected}
            setSelected={setSelected}
            lessonName={lessonName}
            filterCriteria={filterCriteria}
            lessonId={lessonId}
          />
          <TableContainer>
            <Table
              sx={{ minWidth: 600 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>

                      <TableCell align="center">{row.lastName}</TableCell>
                      <TableCell align="center">{row.firstName}</TableCell>
                      <TableCell align="center">
                        <span
                          style={{
                            backgroundColor:
                              row.attendance === 0
                                ? "#E2001A"
                                : row.attendance === 1
                                ? "#4caf50"
                                : row.attendance === 2
                                ? "#efb100"
                                : "#000",
                            color: "#fff",
                            padding: "5px 10px",
                            borderRadius: "25px",
                          }}
                        >
                          {row.attendance === 0
                            ? "Abwesend"
                            : row.attendance === 1
                            ? "Anwesend"
                            : row.attendance === 2
                            ? "Entschuldigt"
                            : row.attendance === 3
                            ? "Vorlesungsfrei"
                            : "Unbekannt"}
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <Button
        variant="contained"
        color="error"
        style={{ width: "15%", marginBottom: "25px" }} // Hier kannst du die Breite in Prozent ändern
        onClick={() => exportPdf()}
      >
        Tabelle exportieren
      </Button>
    </>
  );
}
