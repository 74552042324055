import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import deLocale from '@fullcalendar/core/locales/de';
import MenuButton from "../../../components/menu";
import styled from "styled-components";
import GetRequest from "../../../components/getFormular";
import Filter from "./filterCourse";

/**
 * Function component representing the calendar.
 * @returns {JSX.Element} The calendar component.
 */
export function Calendar() {
  const [events, setEvents] = useState([]);
  const [weekStartDate, setWeekStartDate] = useState(null);
  const [earliestHour, setEarliestHour] = useState(null);
  const [latestHour, setLatestHour] = useState(null);
  const [courseId] = useState("");
  const [filterCriteria, setFilterCriteria] = useState({});
  const [showChangeButton, setShowChangeButton] = useState(false); // Variable zur Anzeige des Änderungsbuttons
  const [facultyIdValue, setFacultyIdValue] = useState(null); // State variable to store facultyId

    /**
   * Handles the change of course ID.
   * @param {string} newCourseId - The new course ID.
   */
  const handleCourseIdChange = (newCourseId) => {
    setFacultyIdValue(newCourseId);

  };

    /**
   * Fetches faculty profile from the server.
   * @returns {Promise<string>} The faculty ID.
   */
  const fetchFacultyProfile = async () => {
    try {
      const profileResponse = await GetRequest("auth/profile");
      return profileResponse.data.facultyId; // Assuming the facultyId is directly available in the response

    } catch (error) {
      console.error("Error fetching faculty profile:", error);
      throw error; // Rethrow the error to handle it outside
    }
  }


  useEffect(() => {
    fetchFacultyProfile()
    .then(facultyId => {
      let showChangeButtonValue = false; // Use a different variable name to avoid confusion

      if (facultyId === null || facultyId === undefined || facultyId === 0) {
        showChangeButtonValue = true; // Update the variable value

      }
      setFacultyIdValue(facultyId);
      // Update state variables instead of assigning directly
      setShowChangeButton(showChangeButtonValue); // Update the state variable
    }    )
    .catch(error => {
      console.error("Error occurred:", error);
    });
  }, []); // Empty dependency array to run only once on component mount


  useEffect(() => {
    const formatDateTime = (dateTime) => {
      const dateObj = new Date(dateTime);
      const year = dateObj.getFullYear();
      const month = (`0${dateObj.getMonth() + 1}`).slice(-2);
      const day = (`0${dateObj.getDate()}`).slice(-2);
      const hours = (`0${dateObj.getHours()}`).slice(-2);
      const minutes = (`0${dateObj.getMinutes()}`).slice(-2);

      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:00`;
      return formattedDate;
    };

    const fetchDataAndUpdateDate = async (startDate) => {
      try {
        const response = await GetRequest(`faculties/${facultyIdValue}/calendar/${startDate}`);

        if (response.data.length === 0) {
          const emptyEvent = {
            title: 'Keine Vorlesung',
            start: startDate + 'T08:00:00',
            end: startDate + 'T12:00:00',
            course: 'in dieser',
            room: 'Woche!',
          };

          const formattedEvents = [emptyEvent];


          const earliest = 8;
          const latest = 12;
          setEarliestHour(earliest);
          setLatestHour(latest);
          setEvents(formattedEvents);
        } else {
          //if array is not empty
          const formattedEvents = response.data.map(event => ({
            title: event.name,
            start: formatDateTime(event.startsAt),
            end: formatDateTime(event.endsAt),
            course: event.courses.join(', '),
            room: event.rooms,
          }));

          const earliest = Math.min(...formattedEvents.map(event => new Date(event.start).getHours()));
          const latest = Math.max(...formattedEvents.map(event => new Date(event.end).getHours()));

          setEarliestHour(earliest);
          setLatestHour(latest);
          setEvents(formattedEvents);
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
      }
    };

    if (weekStartDate) {
      fetchDataAndUpdateDate(weekStartDate);
    }
  }, [facultyIdValue, weekStartDate,courseId]);

  return (
    <div>
      <Container>
        <MenuButton />
      </Container>
      <Title>Kalender</Title>
      {showChangeButton ? <Filter
        onChangeCourseId={handleCourseIdChange}
        setFilterCriteria={setFilterCriteria}
      /> : null}
      <div>
        <FullCalendar
          plugins={[timeGridPlugin]}
          initialView='timeGridWeek'
          events={events}
          slotMinTime={earliestHour ? `${earliestHour}:00:00` : '07:00:00'}
          slotMaxTime={latestHour ? `${latestHour + 1}:00:00` : '22:00:00'}
          allDaySlot={false}
          eventContent={renderEventContent}
          hiddenDays={[0]}
          locale={deLocale}
          eventColor='#E2001A'
          eventTextColor='#ffffff'
          buttonIcons={false}
          height={800}
          slotDuration='00:15:00'
          datesSet={(info) => {


let startDate = new Date(info.start);

// add 1 day to the start date
startDate.setDate(startDate.getDate() + 1);


startDate = startDate.toISOString().split('T')[0];

            setWeekStartDate(startDate);
          }}
        />
      </div>
    </div>
  );
}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      {" "} {/* Leerzeichen */}
      <i>{eventInfo.event.title}</i>
      {" "} {/* Leerzeichen */}
      <i>{eventInfo.event.extendedProps.course}</i>
      {" "} {/* Leerzeichen */}
      <i>{"["}{eventInfo.event.extendedProps.room}{"]"}</i>
    </>
  );
}

const Title = styled.div`
  width: 100%;
  font-size: 48px;
  font-family: Inter, sans-serif;
  font-weight: 600;
`;

const Container = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

export default Calendar;
