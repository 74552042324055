import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  DialogActions,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

/**
 * ErrorDialog component displays an error dialog with a given error message.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Determines whether the dialog is open or not.
 * @param {function} props.onClose - Callback function to handle dialog close event.
 * @param {string} props.errorMessage - The error message to be displayed in the dialog.
 * @returns {JSX.Element} - The rendered ErrorDialog component.
 */
const ErrorDialog = ({ open, onClose, errorMessage }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Fehler!</DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ErrorIcon
            style={{
              color: "#f44336",
              fontSize: "50px",
              marginRight: "10px",
            }}
          />
          {errorMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
