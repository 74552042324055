/**
 * @fileoverview SignUp.js is the page for the user to sign up for an account.
 *  @description It is a simple page with logo and sign up form.
 *
 * @returns {SignUp} SignUp page.
 *
 */

import React from "react";
import styled from "styled-components";
import PasswordFormula from "./components/passwordFormula";

/**
 * Renders the SetPassword component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the component.
 * @param {string} props.linkText - The text for the link.
 * @param {Function} props.action - The action to be performed.
 * @returns {JSX.Element} The rendered SetPassword component.
 */
const SetPassword = ({ title, linkText, action }) => {
  return (
    <div>
      <Logo loading="lazy" src="/DHlogo_red.png" alt="logo" />
      <PasswordFormula title={title} linkText={linkText} action={action}/>
    </div>
  );
};

export default SetPassword;

const Logo = styled.img`
  width: 130px;
  height: auto;
  padding: 20px;
`;
