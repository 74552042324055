import React, { useEffect, useState } from "react";
import RoleSelection from "./roleSelection";
import styled from "styled-components";
import axios from "axios";

import DeleteRequest from "../../../../components/deleteFormula";

import { Button } from "@mui/material";
import ConfirmDialog from "../../../../components/confirmDialog";
import SuccessDialog from "../../../../components/successDialog";
import ErrorDialog from "../../../../components/errorDialog";

/**
 * Represents a form for deleting an account.
 *
 * @component
 */
const DeleteAccountForm = () => {
  const [selectedRole, setSelectedRole] = useState("");
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [accountFound, setAccountFound] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [studentId, setStudentId] = useState("");
  const [managerId, setManagerId] = useState("");
  const [multipleAccounts, setMultipleAccounts] = useState(false);
  const [email, setEmail] = useState("");
  const [, setResetSelectedRole] = useState(false);

  // open confirm dialog
  const handleSubmitButton = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseSuccessDialog = () => {
    setSuccessDialogOpen(false);
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  /**
   * @brief resets the selected role when resetSelectedRole changes
   */
  const resetRoleSelection = () => {
    setResetSelectedRole(true);
    setTimeout(() => {
      setResetSelectedRole(false);
    }, 100);
  };

  /**
   * @brief deletes the account and opens success/error dialog
   */
  const handleConfirm = async () => {
    console.log("Account deleted");
    setOpenDialog(false);
    if (selectedRole === "Student") {
      try {
        const response = await DeleteRequest(`students/${studentId}`);
        console.log(response.status);

        if (response.status === 200) {
          // reset data
          setName("");
          setFirstName("");
          setSuccessDialogOpen(true);
          setAccountFound(false);
          resetRoleSelection();
        } else {
          setErrorDialogOpen(true);
        }
      } catch (error) {
        setErrorDialogOpen(true);
        console.log(error);
        setAccountFound(false);
      }
    }
    if (selectedRole === "Verwalter/Admin") {
      try {
        const response = await DeleteRequest(`managers/${managerId}`);
        console.log(response.status);
        if (response.status === 200) {
          // reset data
          setName("");
          setFirstName("");
          setSuccessDialogOpen(true);
          resetRoleSelection();
        } else {
          setErrorDialogOpen(true);
        }
      } catch (error) {
        console.log(error);
        setErrorDialogOpen(true);
      }
    }
  };

  /**
   * Fetches the account information based on the provided role.
   *
   * @param {string} role - The role of the account (e.g., "students", "managers").
   * @returns {Promise<void>} - A promise that resolves when the account information is fetched.
   */
  const getAccount = async (role) => {
    //fetch all existing students
    const URL = `https://api.studicheckin.afink.dev/${role}`;
    const response = await axios.get(URL);
    //check if student exists
    // check if there are more students with the same name
    if (!email) {
      const matchingAccounts = response.data.filter((student) => {
        return student.lastName === name && student.firstName === firstName;
      });

      if (matchingAccounts.length === 1) {
        if (role === "students") {
          setStudentId(matchingAccounts[0].id);
        } else {
          setManagerId(matchingAccounts[0].id);
        }
        setAccountFound(true);
      } else if (matchingAccounts.length > 1) {
        // More than one student with the same name found
        // You can handle this case according to your requirement
        setMultipleAccounts(true);
        console.log(
          "Multiple accounts with the same name found:",
          matchingAccounts
        );
        setAccountFound(false);
      } else {
        setAccountFound(false);
      }
    } else {
      const matchingAccounts = response.data.filter((role) => {
        return (
          role.lastName === name &&
          role.firstName === firstName &&
          role.email === email
        );
      });
      if (matchingAccounts.length === 1) {
        if (role === "students") {
          setStudentId(matchingAccounts[0].id);
        } else {
          setManagerId(matchingAccounts[0].id);
        }
        setAccountFound(true);
        setMultipleAccounts(false);
      } else {
        setAccountFound(false);
      }
    }
  };

  /**
   * @brief Fetches account data from the backend and checks if the account exists
   */
  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        if (selectedRole === "Student") {
          getAccount("students");
        } else if (selectedRole === "Verwalter/Admin") {
          console.log("fetching manager data");
          getAccount("managers");
        }
      } catch (error) {
        console.error("Error fetching account data:", error);
      }
    };

    fetchAccountData();
  }, [selectedRole, name, firstName, email]);


  return (
    <>
      <h3>
        Geben Sie hier die benötigten Daten an, um einen Account zu löschen
      </h3>
      <RoleSelection
        onSelect={handleRoleSelect}
        resetRoleSelection={resetRoleSelection}
      />
      <Container>
        <label htmlFor="name" style={labelStyle}>
          Name:
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setAccountFound(false);
          }}
          required
          style={inputStyle}
        />
      </Container>
      {/* firstName Container */}
      <Container>
        <label htmlFor="firstName" style={labelStyle}>
          Vorname:
        </label>
        <input
          type="text"
          id="firstName"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
            setAccountFound(false);
          }}
          required
          style={inputStyle}
        />
      </Container>

      {/* multiple Accounts found */}
      {multipleAccounts && (
        <>
          <Container>
            <label htmlFor="firstName" style={labelStyle}>
              E-Mail
            </label>
            <input
              type="text"
              id="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              required
              style={inputStyle}
            />
          </Container>
          <div style={{ color: "red", fontSize: "16px", padding: "16px" }}>
            Mehrere Accounts mit dem gleichen Namen gefunden. Bitte geben Sie
            die Email an.
          </div>
        </>
      )}
      {/*  account not found */}
      {!accountFound && !multipleAccounts && (
        <div style={{ color: "red", fontSize: "16px" }}>
          Kein Account gefunden. Bitte geben Sie einen gültigen Namen ein.
        </div>
      )}

      {/* account found */}
      {accountFound && (
        <div style={{ color: "green", fontSize: "16px" }}>
          Account gefunden. Sie können den Account jetzt löschen.
        </div>
      )}

      {/* student delete button */}
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={handleSubmitButton}
        disabled={
          !(accountFound && selectedRole === "Student" && !multipleAccounts)
        }
        style={{
          backgroundColor: "#E2001A",
          marginTop: "20px",
          width: "20%",
        }}
      >
        Student löschen
      </Button>
      {/* manager delete button */}
      {accountFound && selectedRole === "Verwalter/Admin" && (
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleSubmitButton}
          style={{
            backgroundColor: "#E2001A",
            marginTop: "20px",
            width: "140px",
          }}
        >
          Verwalter löschen
        </Button>
      )}
      {/* Dialog to confirm the deletion of the account*/}
      <ConfirmDialog
        open={openDialog}
        onClose={handleClose}
        onConfirm={handleConfirm}
        confirmText="Möchten Sie diesen Account wirklich löschen?"
        dialogTitle="Acccount löschen"
      />
      {/* Success-Pop-up */}
      <SuccessDialog
        open={successDialogOpen}
        onClose={handleCloseSuccessDialog}
        text="Account wurde erfolgreich gelöscht."
      />
      {/* Error-Pop-up */}
      <ErrorDialog
        open={errorDialogOpen}
        onClose={handleCloseErrorDialog}
        errorMessage="Account konnte nicht gelöscht werden."
      />
    </>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const labelStyle = {
  marginRight: "10px",
  width: "140px",
  minWidth: "140px",
};

const inputStyle = {
  border: "1px solid #E2001A",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  fontSize: "20px",
  padding: "8px 12px",
  borderRadius: "4px",
  flex: 1,
};

export default DeleteAccountForm;
