import React from "react";
import Footer from "../../components/footer";
import Navbar from "../../components/header";
import Calendar from "./components/calendar";
import styled from "styled-components";

/**
 * Functional component representing the CalendarPage.
 * @returns {JSX.Element} The CalendarPage component.
 */
const CalendarPage = () => {
  return (
    <div>
      <Navbar />
      <Calendardiv>
      <Calendar/> 
      </Calendardiv>
      <Footer />
    </div>
  );
};

const Calendardiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 5%; /* Default padding */
  
  @media screen and (min-width: 800px) {
    padding: 0 10%; /* Add 10% padding on screens larger than 768px */
  }
`;

export default CalendarPage;
