import React, { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "styled-components";
import GetRequest from "../../../components/getFormular";
import { useAuth } from "../../../auth/AuthContext";

//TODO: if user is no admin, show only courses for own faculy of user
const Filter = ({ setFilterCriteria, onChangeCourseId }) => {
  const [selectedCourse, setSelectedCourse] = useState("");
  const [courseIds, setCourseIds] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [courses, setCourses] = useState([]);
  const { facultyId } = useAuth();

  /**
   * @brief called when courseId in the table changes
   * @description sets the courseId in the parent component
   */
  useEffect(() => {
    onChangeCourseId(courseIds[selectedCourse]);
  }, [selectedCourse]);

  /**
   * @brief fetch all existing courses
   */
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        if (facultyId) {
          const response = await GetRequest(`faculties`);
          const coursesData = response.data;
          const coursesShortNames = coursesData.map(
            (course) => course.name
          );
          const coursesMap = coursesData.reduce((acc, course) => {
            acc[course.name] = course.id;
            return acc;
          }, {});

          setCourses(coursesShortNames);
          setCourseIds(coursesMap);
        } else {
          const response = await GetRequest("faculties");
          const coursesData = response.data;
          const coursesShortNames = coursesData.map(
            (course) => course.name
          );
          const coursesMap = coursesData.reduce((acc, course) => {
            acc[course.name] = course.id;
            return acc;
          }, {});

          setCourses(coursesShortNames);
          setCourseIds(coursesMap);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchCourses();
  }, [facultyId]);

  /**
   * @brief
   * @param {*} course
   */
  const handleCourseSelect = async (course) => {
    setSelectedCourse(course);
    setAnchorEl(null);

    // call callback function to set the filter criteria
    setFilterCriteria({
      courseId: courseIds[course],
    });
  };

  return (
    <div>
      <h1>Sie sind Admin</h1>
      <p>
        Bitte wählen Sie eine Fakultät aus, um Daten zu erhalten.
      </p>
      <Container>
        <label htmlFor="courses">Fakultät: </label>
        <Button
          id="course-button"
          aria-label="Kurse"
          aria-controls="course-menu"
          aria-haspopup="true"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<KeyboardArrowDownIcon />}
          style={buttonStyle}
        >
          {selectedCourse || "Fakultät"}
        </Button>
        <Menu
          id="course-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {courses.map((course) => (
            <MenuItem key={course} onClick={() => handleCourseSelect(course)}>
              {course}
            </MenuItem>
          ))}
        </Menu>
      </Container>
    </div>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  /* Hier wird das Padding zwischen dem Label und dem Dropdown-Menü hinzugefügt */
  label {
    margin-right: 10px; /* Standardabstand */
  }
`;

const buttonStyle = {
  color: "black",
  border: "1px solid #E2001A",
  fontFamily: "Arial, sans-serif",
  textTransform: "none",
  fontSize: "20px",
};

export default Filter;
