/**
 * @brief this file is ueed to create a context for the authentication and to
 * store the admin state of a user globally
 */

import React, { createContext, useContext, useEffect, useState } from "react";
import GetRequest from "../components/getFormular";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

/**
 * @brief This component is responsible for the authentication of a user
 * @description It checks if a user is authenticated and if he is an admin and sets the parameters accordingly
 * it is used to check whether a user is verified when trying to access a protected route
 * @param {*} param0
 * @returns
 */
export const AuthProvider = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [facultyId, setFacultyId] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    /**
     * Checks if the user is authenticated and sets the necessary state variables accordingly.
     * If the user is authenticated, it retrieves the user profile information and updates the state variables.
     * If the user is not authenticated or an error occurs, it updates the state variables accordingly.
     */
    const checkAuthenticated = async () => {
      const token = localStorage.getItem("Authorization");
      if (token) {
        try {
          const response = await GetRequest("auth/profile");

          if (response.status === 200) {
            setIsAuthenticated(true);
            setIsAdmin(response.data.isAdmin);
            setFacultyId(response.data.facultyId);
            setIsLoading(false);
            setUserId(response.data.id);
          } else {
            setIsAuthenticated(false);
            setIsAdmin(false);
            setFacultyId("");
            setIsLoading(false);
          }
        } catch (error) {
          setIsAuthenticated(false);
          setIsAdmin(false);
          setFacultyId("");
          setIsLoading(false);
          console.log(error);
        }
      } else {
        setIsAuthenticated(false);
        setIsAdmin(false);
        setIsLoading(false);
        console.log("No token found");
      }
    };

    checkAuthenticated();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        userId,
        setUserId,
        isAdmin,
        setIsAdmin,
        isAuthenticated,
        setIsAuthenticated,
        isLoading,
        setIsLoading,
        facultyId,
        setFacultyId,

      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
