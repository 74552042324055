import React, { useState } from "react";
import { Button, TextField, Typography, Container, Link } from "@mui/material";
import PasswordChecklist from "react-password-checklist";

import PostRequest from "../../../components/postFormula";

import SuccessDialog from "../../../components/successDialog";

/**
 * Renders a form for setting a password.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the form.
 * @param {string} props.linkText - The text for the link to the login page.
 * @param {string} props.action - The action type for the form (invitation or reset-password).
 * @returns {JSX.Element} The PasswordFormula component.
 */
const PasswordFormula = ({ title, linkText, action }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  /**
   * @brief handles the submit of the form and sends the password and the token to the backend
   * @param {*} event
   * @returns
   */
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Extract token from URL query string
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    console.log(action);

    if (!token) {
      setError(
        "Kein Token gefunden. Fragen Sie ihren Manager erneut nach einem Link."
      );
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwörter stimmen nicht überein");
      return;
    }

    if (action === "invitation") {
      try {
        const response = await PostRequest({
          data: {
            invitationToken: token,
            password,
          },
          path: "auth/invitation",
        });

        if (response.status === 201) {
          window.location.href = "/login";
        }
      } catch (error) {
        setError(
          "Registrierung fehlgeschlagen. Fragen Sie ihren Manager erneut nach einem Einladungslink."
        );
        console.error("Error:", error);
      }
    }

    if (action === "reset-password") {
      try {
        const response = await PostRequest({
          data: {
            passwordResetToken: token,
            password,
          },
          path: "auth/reset-password",
        });

        if (response.status === 201) {
          window.location.href = "/login";
        }
      } catch (error) {
        setError(
          "Passwort zurücksetzen fehlgeschlagen. Fragen Sie ihren Manager erneut nach einem Link zum Zurücksetzen des Passworts."
        );
        console.error("Error:", error);
      }
    }
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" align="center" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2" align="center" gutterBottom>
        {linkText}
      </Typography>

      <form onSubmit={handleSubmit}>
        <TextField
          sx={{ marginBottom: 2 }}
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          fullWidth
          required
        />
        <TextField
          sx={{ marginBottom: 2 }}
          label="Confirm Password"
          variant="outlined"
          type="password"
          value={confirmPassword}
          onChange={(event) => setConfirmPassword(event.target.value)}
          fullWidth
          required
        />
        <PasswordChecklist
          rules={["minLength", "specialChar", "number", "capital", "match"]}
          minLength={8}
          value={password}
          valueAgain={confirmPassword}
          messages={{
            minLength: "Mindeslänge von 8 Zeichen.",
            specialChar: "Das Passwort enthält ein Sonderzeichen.",
            number: "Das Passwort enthält eine Zahl.",
            capital: "Das Passwort enthält einen Großbuchstaben.",
            match: "Die Passwörter stimmen nicht überein.",
          }}
        />
        <Typography variant="body2" align="center" gutterBottom>
          Sie besitzen bereits einen Account?{" "}
          <Link href="/login" underline="hover" color="secondary">
            Einloggen
          </Link>
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <Button
          sx={{
            color: "white",
            backgroundColor: "#E2001A",
            "&:hover": {
              backgroundColor: "#E2001A",
            },
          }}
          type="submit"
          variant="contained"
          fullWidth
        >
          Passwort festlegen
        </Button>
      </form>
    </Container>
  );
};

export default PasswordFormula;
