import axios from "axios";

const BASE_URL = `https://api.studicheckin.afink.dev/`;
/**
 * Sends a GET request to the specified path using axios.
 * @param {string} path - The path to send the GET request to.
 * @returns {Promise} - A promise that resolves to the response data.
 * @throws {Error} - If there is an error while sending the GET request.
 */
const GetRequest = async (path) => {
  try {
    console.log("Sending GET request...");
    const response = await axios.get(`${BASE_URL}${path}`);
    console.log("Response ", response);
    console.log("GET request was successful!");
    return response; // Erfolgreiche Antwort zurückgeben
  } catch (error) {
    console.error("Error while sending GET request:", error);
    throw new Error("Fehler beim Senden des GET-Anforderung");
  }
};

export default GetRequest;
