import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ProgressBar from "../../../components/progressbar";
import DonutProgressBar from "../../../components/progressdonut";
import AnzahlLanding from "../../../components/AnzahlLanding";
import { Link } from "react-router-dom";
import CalendarDayGrid from "./calendardaygrid";
import { useAuth } from "../../../auth/AuthContext";

import axios from "axios";

/**
 * @brief This component is responsible for the main menu of the application, it shows all cards with the different functionalities and the stats for the dashboard
 * @returns {JSX.Element} MainMenu
 */
const MainMenu = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [, setError] = useState(null);
  const { userId } = useAuth();

  /**
   *  @brief Fetches the dashboard data from the backend and sets the state accordingly.
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.studicheckin.afink.dev/managers/${userId}/dashboard`
        );
        setDashboardData(response.data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [userId]);

  if (!dashboardData) {
    return <Title>Server nicht erreichbar.</Title>;
  }
  let percentageExcusedAbsences;

  if (
    dashboardData.excusedAbsences !== 0 ||
    dashboardData.unexcusedAbsences !== 0
  ) {
    percentageExcusedAbsences = Math.round(
      (dashboardData.excusedAbsences /
        (dashboardData.excusedAbsences + dashboardData.unexcusedAbsences)) *
        100
    );
  } else {
    percentageExcusedAbsences = 100;
  }

  const percentageOccupiedRooms = Math.round(
    (dashboardData.occupiedRooms / dashboardData.rooms) * 100
  );
  const percentageWorkingScanners = Math.round(
    (dashboardData.workingScanners / dashboardData.scanners) * 100
  );

  return (
    <Main>
      <GridContainer>
        <Grid>
          <Card>
            <Link
              to="/lecture"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <CardContent>
                <IconTitleContainer>
                  <Title>Vorlesung</Title>
                  <Icon src="books-icon.png" alt="books" />
                </IconTitleContainer>
                <Description>
                  Hier befindet sich eine Übersicht aller Vorlesungen mit den
                  zugehörigen Dozierenden und Kursen.
                </Description>
                <Anzahl1>
                  <AnzahlLanding number={dashboardData.lessons} />
                  <Description>geplante Vorlesungen</Description>
                </Anzahl1>
              </CardContent>
            </Link>
          </Card>

          <Card>
            <Link
              to="/attendance"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <CardContent>
                <IconTitleContainer>
                  <Title>Anwesenheit</Title>
                  <Icon src="clock-icon.png" alt="clock" />
                </IconTitleContainer>

                <Anzahl>
                  <AnzahlLanding
                    number={
                      dashboardData.unexcusedAbsences +
                      dashboardData.excusedAbsences
                    }
                  />
                  <Description>Personen ohne Check-in</Description>
                </Anzahl>
                <Donut>
                  <Description2>
                    Hier befindet sich eine Übersicht der Anwesenheit. Die
                    Tabelle kann nach verschiedenen Kriterien sortiert werden.
                  </Description2>
                  <chart>
                    <DonutProgressBar percentage={percentageExcusedAbsences} />
                  </chart>
                </Donut>
              </CardContent>
            </Link>
          </Card>

          <Card>
            <Link
              to="/roomoverview"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <CardContent>
                <IconTitleContainer>
                  <Title>Raumübersicht</Title>
                  <Icon src="home_black-icon.png" alt="clock" />
                </IconTitleContainer>
                <Description>
                  Hier befindet sich eine Übersicht aller Räume sowie deren
                  Belegung.
                </Description>
                <ProgressBarContainer>
                  <ProgressBar percentage={percentageOccupiedRooms} />
                </ProgressBarContainer>
                <Description>aller Räume sind aktuell belegt.</Description>
              </CardContent>
            </Link>
          </Card>

          <Card>
            <Link
              to="/courseoverview"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <CardContent>
                <IconTitleContainer>
                  <Title>Kursübersicht</Title>
                  <Icon src="graduation-icon.png" alt="clock" />
                </IconTitleContainer>
                <Description>
                  Hier befindet sich eine Übersicht aller Kurse mit allen
                  zugehörigen Studierenden.
                </Description>
                <Anzahl1>
                  <AnzahlLanding number={dashboardData.courses} />
                  <Description>verschiedene Kurse</Description>
                </Anzahl1>
              </CardContent>
            </Link>
          </Card>

          <Card>
            <Link
              to="/scanneroverview"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <CardContent>
                <IconTitleContainer>
                  <Title>Geräteübersicht</Title>
                  <Icon src="phone-icon.png" alt="computer" />
                </IconTitleContainer>
                <Description>
                  Hier befindet sich eine Übersicht aller Check-in-Geräte, ihrer
                  Verwaltung, Fehlermeldungen und die Integration.
                </Description>
                <ProgressBarContainer>
                  <ProgressBar percentage={percentageWorkingScanners} />
                </ProgressBarContainer>
                <Description>aller Geräte funktionsbereit</Description>
              </CardContent>
            </Link>
          </Card>

          <Card>
            <Link
              to="/calendar"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <CardContent>
                <IconTitleContainer>
                  <Title>Kalender</Title>
                  <Icon src="appointment-icon.png" alt="calendar" />
                </IconTitleContainer>
                <CalendarContainer>
                  <CalendarDayGrid />
                </CalendarContainer>
              </CardContent>
            </Link>
          </Card>
          <Card>
            <Link
              to="/studentoverview"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <CardContent>
                <IconTitleContainer>
                  <Title>Studierende</Title>
                  <Icon src="multiple_users-icon.png" alt="student" />
                </IconTitleContainer>
                <Description>
                  Hier befindet sich eine Übersicht aller registrierten
                  Accountsund stellt die Möglichkeit zur Änderung der
                  Benutzerrechte.{" "}
                </Description>
                <Anzahl1>
                  <AnzahlLanding number={dashboardData.students} />
                  <Description>Studierende in der Informatik</Description>
                </Anzahl1>
              </CardContent>
            </Link>
          </Card>

          <Card>
            <Link
              to="/accounts"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <CardContent>
                <IconTitleContainer>
                  <Title>Accounts</Title>
                  <Icon src="profile_avatar-icon.png" alt="student" />
                </IconTitleContainer>
                <Description>
                  Hier befindet sich eine Übersicht aller registrierten Accounts
                  und stellt die Möglichkeit zur Änderung der Benutzerrechte.{" "}
                </Description>
                <Anzahl1>
                  <AnzahlLanding number={dashboardData.managers} />
                  <Description>registrierte Verwaltungs-Accounts</Description>
                </Anzahl1>
              </CardContent>
            </Link>
          </Card>

          <Card>
            <Link
              to="/help"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <CardContent>
                <IconTitleContainer>
                  <Title>Hilfe</Title>
                  <Icon src="information-icon.png" alt="help" />
                </IconTitleContainer>
                <Description>
                  Hier sind der Support, sowie Ansprechpersonen für alle
                  weiteren Probleme zu finden. Sowie die Gründe für die
                  Entstehung dieser Verwaltungsplattform.
                </Description>
              </CardContent>
            </Link>
          </Card>
        </Grid>
      </GridContainer>
    </Main>
  );
};

const Main = styled.div`
  margin-top: 20px;
`;

const GridContainer = styled.div`
  padding-top: 20px;
  margin-bottom: 40px;
  height: auto;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
  gap: 20px;
  justify-items: center;
  align-items: center;
  margin: 0 auto;
  maxwidth: 1400px;
  padding: 0 100px;
`;

const Card = styled.div`
  display: flex;
  width: 400px;
  height: 256px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 6px 6px 12px 0px rgba(0, 0, 0, 0.2),
    2px 2px 4px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid #eaeaea;
  flex-direction: column;
  margin-bottom: 20px;
`;

const CardContent = styled.div`
  text-align: left;
  padding: 20px;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 24px;
`;

const Description = styled.p`
  margin-bottom: 4px;
  margin-top: 4px;
  margin-right: 0px;
  margin-left: 0px;
`;

const Description2 = styled.p`
  margin-bottom: 4px;
  margin-top: 8px;
  margin-right: 0px;
  margin-left: 0px;
  max-width: 200px;
  font-size: 14px;
  font-weight: lighter;
`;

const Icon = styled.img`
  width: 24px;
  right: 20px;
  height: auto;
`;

const IconTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;
`;

const ProgressBarContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 12px;
`;

const Anzahl = styled.div`
  margin-top: 5px;
  margin-left: 0px;
  line-height: 1;
`;

const Anzahl1 = styled.div`
  margin-top: 15px;
  margin-left: 0px;
  line-height: 1;
`;

const Donut = styled.div`
  margin-top: 5px;
  line-height: 1;
  max-width: 3600px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
`;

const CalendarContainer = styled.div`
  margin: 0px;
  margin-top: -10px;
`;
export default MainMenu;
