import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import deLocale from '@fullcalendar/core/locales/de';
import GetRequest from "../../../components/getFormular";

import { createGlobalStyle } from 'styled-components';

// Globale CSS-Regel, um das Attribut --fc-today-bg-color zu ändern
const GlobalStyle = createGlobalStyle`
  :root {
    --fc-today-bg-color: #E2001A; /* Ändern Sie die Hintergrundfarbe hier */
  }
`;

/**
 * Renders a calendar day grid component.
 * @returns {JSX.Element} The rendered calendar day grid component.
 */
const CalendarDayGrid = () => {
  const [events, setEvents] = useState([]);
  const [, setWeekStartDate] = useState(null);
  const [facultyIdValue, setFacultyIdValue] = useState(null); // State variable to store facultyId



  /**
   * Fetches the faculty profile and returns the facultyId.
   * @returns {Promise<number>} The facultyId obtained from the profile response.
   * @throws {Error} If there is an error while fetching the faculty profile.
   */
  const fetchFacultyProfile = async () => {
    try {
      const profileResponse = await GetRequest("auth/profile");
      return profileResponse.data.facultyId; // Assuming the facultyId is directly available in the response

    } catch (error) {
      console.error("Error fetching faculty profile:", error);
      throw error; // Rethrow the error to handle it outside
    }
  }


  useEffect(() => {
    fetchFacultyProfile()
    .then(facultyId => {
      setFacultyIdValue(facultyId);
      // Update state variables instead of assigning directly
    }    )
    .catch(error => {
      console.error("Error occurred:", error);
    });
  }, []); // Empty dependency array to run only once on component mount



  useEffect(() => {
    /**
     * Fetches scanner data and updates the events state.
     * @returns {Promise<void>} A Promise that resolves when the scanner data is fetched and the events state is updated.
     */
    const fetchScannerData = async () => {
      try {
        const currentDate = new Date();
        const formattedWeekStartDate = `${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${('0' + currentDate.getDate()).slice(-2)}`;
        setWeekStartDate(formattedWeekStartDate);

      // check if admin
      if (facultyIdValue === null) {
        setEvents([{ title: "Keine Fakultät zugeordnet",  start: `${formattedWeekStartDate}T08:00:00`, end: `${formattedWeekStartDate}T09:00:00`}]);
        return;
      }

        const response = await GetRequest(`faculties/${facultyIdValue}/calendar/${formattedWeekStartDate}`);
        const formattedEvents = response.data.map(event => ({
          title: event.name,
          start: formatDateTime(event.startsAt),
          end: formatDateTime(event.endsAt),
        }));

        if (formattedEvents.length === 0) {
          console.log('Keine Daten vorhanden');
          setEvents([{ title: "Keine verfügbaren Daten",  start: `${formattedWeekStartDate}T08:00:00`, end: `${formattedWeekStartDate}T09:00:00`}]);
        }
        else {
          setEvents(formattedEvents);
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
      }
    };

    fetchScannerData();
  }, [facultyIdValue]);

  /**
   * Formats a given date and time into a string in the format "YYYY-MM-DDTHH:MM:00".
   *
   * @param {string} dateTime - The date and time to format.
   * @returns {string} The formatted date and time string.
   */
  const formatDateTime = (dateTime) => {
    const dateObj = new Date(dateTime);
    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}:00`;
  };


  return (
    <div>
      <GlobalStyle />
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView='dayGridDay'
        events={events}
        locale={deLocale}
        eventContent={renderEventContent}
        headerToolbar={false}
        height={155}
      />
    </div>
  );
  }

function renderEventContent(eventInfo) {
  return (
    <div style={{ color: 'white' }}>
      <b>{eventInfo.timeText}</b>
      {" "}
      <i>{eventInfo.event.title}</i>
    </div>
  );
};
export default CalendarDayGrid;
