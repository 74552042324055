import React from 'react';
import Navbar from '../../components/header';
import Footer from '../../components/footer';
import RoomOverviewTable from './components/roomOverview';
import styled from 'styled-components';

/**
 * Renders the Room Overview page.
 * @returns {JSX.Element} The Room Overview page component.
 */
const RoomOverviewPage = () => {
    return (
      <div>
        <Navbar />
        <Roomdiv>
        <RoomOverviewTable />
        </Roomdiv>
        <Footer />
      </div>
    );
  };

  const Roomdiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 5%; /* Default padding */

  @media screen and (min-width: 800px) {
    padding: 0 10%; /* Add 10% padding on screens larger than 768px */
  }
`;
  export default RoomOverviewPage;