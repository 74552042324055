import React from 'react';
import './DonutProgressBar.css';

/**
 * A circular progress bar component that displays a donut shape with a given percentage.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.percentage - The percentage value to display in the progress bar.
 * @returns {JSX.Element} - The rendered component.
 */
const DonutProgressBar = ({ percentage }) => {
  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const progress = circumference - (percentage / 100) * circumference;

  return (
    <div className="donut-progress-bar">
      <svg height="120" width="120">
        <circle
          className="donut-progress-bar-background"
          cx="60"
          cy="60"
          r={radius}
        />
        <circle
          className="donut-progress-bar-foreground"
          cx="60"
          cy="60"
          r={radius}
          style={{ strokeDasharray: circumference, strokeDashoffset: progress }}
        />
      </svg>

      <span className="percentage">{percentage}% davon entschuldigt</span>
    </div>
  );
};

export default DonutProgressBar;
