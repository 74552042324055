import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/daygrid'; // Richtiges Plugin verwenden
import deLocale from '@fullcalendar/core/locales/de';
import MenuButton from "../../../components/menu";
import styled from "styled-components";
import GetRequest from "../../../components/getFormular";
import Filter from "./filterCourse";

/**
 * Functional component representing the calendar.
 * @returns {JSX.Element} The Calendar component.
 */
export function Calendar() {
  const [events, setEvents] = useState([]);
  const [weekStartDate, setWeekStartDate] = useState(null);
  const [earliestHour, setEarliestHour] = useState(null);
  const [latestHour, setLatestHour] = useState(null);
  const [showChangeButton, setShowChangeButton] = useState(false); // Variable zur Anzeige des Änderungsbuttons
  const [facultyIdValue, setFacultyIdValue] = useState(null); // State variable to store facultyId
  const [noEventsReturned, setNoEventsReturned] = useState(false); // State variable to store no events returned flag
  const [courseId] = useState("");
  const [filterCriteria, setFilterCriteria] = useState({});

 /**
   * Handles the change of course ID.
   * @param {string} newCourseId - The new course ID.
   * @returns {void}
   */
  const handleCourseIdChange = (newCourseId) => {
    setFacultyIdValue(newCourseId);

  };
  /**
   * Fetches the faculty profile.
   * @returns {Promise<number>} A promise that resolves to the faculty ID.
   */
  const fetchFacultyProfile = async () => {
    try {
      const profileResponse = await GetRequest("auth/profile");
      return profileResponse.data.facultyId; // Assuming the facultyId is directly available in the response
      
    } catch (error) {
      console.error("Error fetching faculty profile:", error);
      throw error; // Rethrow the error to handle it outside
    }
  }
  
 
  useEffect(() => {
    fetchFacultyProfile()
    .then(facultyId => {
      let showChangeButtonValue = false; // Use a different variable name to avoid confusion

      if (facultyId === null || facultyId === undefined || facultyId === 0) {
        showChangeButtonValue = true; // Update the variable value
       
      } 
      setFacultyIdValue(facultyId);
      // Update state variables instead of assigning directly
      setShowChangeButton(showChangeButtonValue); // Update the state variable
    }    )
    .catch(error => {
      // Fehlerbehandlung hier
      console.error("Error occurred:", error);
    });
  }, []); // Empty dependency array to run only once on component mount
  
  
  useEffect(() => {
    const formatDateTime = (dateTime) => {
      const dateObj = new Date(dateTime);
      const year = dateObj.getFullYear();
      const month = (`0${dateObj.getMonth() + 1}`).slice(-2);
      const day = (`0${dateObj.getDate()}`).slice(-2);
      const hours = (`0${dateObj.getHours()}`).slice(-2);
      const minutes = (`0${dateObj.getMinutes()}`).slice(-2);

      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:00`;
      return formattedDate;
    };
    
/**
 * Fetches data for the calendar and updates it based on the specified start date.
 * @async
 * @param {Date} startDate - The start date for which data is fetched and updated.
 * @returns {Promise<void>} A Promise that resolves once data is fetched and updated.
 */
    const fetchDataAndUpdateDate = async (startDate) => {
      try {
        const response = await GetRequest(`faculties/${facultyIdValue}/calendar/${startDate}`);
        const formattedEvents = response.data.map(event => ({
          
          title: event.name,
          start: formatDateTime(event.startsAt),
          end: formatDateTime(event.endsAt),
          id: event.id,
          createdAt: new Date(event.createdAt),
          updatedAt: new Date(event.updatedAt),
          deletedAt: event.deletedAt ? new Date(event.deletedAt) : null,
          isOnline: event.isOnline,
          isCanceled: event.isCanceled,
          isOptional: event.isOptional,
          forceCheckIn: event.forceCheckIn,
          courses: event.courses,
          room: event.rooms,
        }));

        if (formattedEvents.length === 0) {
          setNoEventsReturned(true); // Update noEventsReturned state variable
        } else {
          setNoEventsReturned(false); // Ensure it's set to false if events are returned
        }

        const earliest = Math.min(...formattedEvents.map(event => new Date(event.start).getHours()));
        const latest = Math.max(...formattedEvents.map(event => new Date(event.end).getHours()));

        setEarliestHour(earliest);
        setLatestHour(latest);

        setEvents(formattedEvents);
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
      }
    };

    if (weekStartDate) {

      fetchDataAndUpdateDate(weekStartDate);
    }
  }, [facultyIdValue, weekStartDate,courseId]);

  return (
    <div>
      <Container>
      <MenuButton />
      </Container>
      <Title>Vorlesungen</Title>
     {showChangeButton ? <Filter
        onChangeCourseId={handleCourseIdChange}
        setFilterCriteria={setFilterCriteria}
      /> : null}
     {noEventsReturned ? <Description>Keine Vorlesung in dieser Woche!</Description> : null}
      <div>
        <FullCalendar
          plugins={[timeGridPlugin]} 
          initialView='dayGridWeek'
          events={events}
          slotMinTime={earliestHour ? `${earliestHour}:00:00` : '07:00:00'}
          slotMaxTime={latestHour ? `${latestHour + 1}:00:00` : '22:00:00'}
          allDaySlot={false} 
          eventContent={renderEventContent} 
          hiddenDays={[0]} 
          locale={deLocale} 
          eventColor='#E2001A' 
          eventTextColor='#ffffff' 
          buttonIcons={false} 
          eventClick= {handleEventClick}


          height={800} 
          slotDuration='00:15:00' 
          datesSet={(info) => {
            const startDate = info.start.toISOString().split('T')[0];
            setWeekStartDate(startDate);
          }}
        />
      </div>
    </div>
  );
}

const handleEventClick = (info,eventInfo) => {
    localStorage.setItem('eventTitle',  info.event.title);
    localStorage.setItem('eventstart', info.event.start);
    localStorage.setItem('eventend', info.event.end);
    localStorage.setItem('eventId', info.event.id);
    localStorage.setItem('eventIsOnline', info.event.extendedProps.isOnline);
    localStorage.setItem('eventIsOptional', info.event.extendedProps.isOptional);
    localStorage.setItem('eventForceCheckIn', info.event.extendedProps.forceCheckIn);
    localStorage.setItem('eventCourses', info.event.extendedProps.courses);
    localStorage.setItem('eventRoom', info.event.extendedProps.room);
    localStorage.setItem('eventIsCanceled', info.event.extendedProps.isCanceled);
    window.open("/eventmenu", "_blank");
};


function renderEventContent(eventInfo) {
    let maxLengthPerLine = 40; // Default

    if (eventInfo.view && eventInfo.view.el) {
      const containerWidth = eventInfo.view.el.offsetWidth;
      maxLengthPerLine = calculateMaxLengthPerLine(containerWidth);
    }

    return (
      <div style={{ color: 'black', textAlign: 'left', whiteSpace: 'normal' }}>
        <div style={{ fontWeight: 'bold' }}>{eventInfo.timeText}</div>
        {splitTitle(eventInfo.event.title, maxLengthPerLine)}
      </div>
    );
  }


  function EventContent(props) {
    const [maxLengthPerLine, setMaxLengthPerLine] = useState(20); // Default

    useEffect(() => {
      function handleResize() {
        const containerWidth = window.innerWidth; 
        const newMaxLengthPerLine = calculateMaxLengthPerLine(containerWidth);
        setMaxLengthPerLine(newMaxLengthPerLine);
      }

      
      window.addEventListener('resize', handleResize);

      
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); 

    return (
      <div style={{ color: 'black', textAlign: 'left', whiteSpace: 'normal' }}>
        <div style={{ fontWeight: 'bold' }}>{props.eventInfo.timeText}</div>
        {splitTitle(props.eventInfo.event.title, maxLengthPerLine)}
      </div>
    );
  }

  function calculateMaxLengthPerLine(containerWidth) {
    const averageCharacterWidth = 10; 
    const padding = 10; 
    const availableWidth = containerWidth - padding;
    const maxLengthPerLine = Math.floor(availableWidth / averageCharacterWidth);

    return maxLengthPerLine;
  }

  function splitTitle(title, maxLengthPerLine) {
    const words = title.split(' ');
    let lines = [];
    let currentLine = '';

    words.forEach(word => {
      if ((currentLine + word).length <= maxLengthPerLine) {
        currentLine += (currentLine === '' ? '' : ' ') + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    });

    if (currentLine !== '') {
      lines.push(currentLine);
    }

    return lines.map((line, index) => <div key={index}>{line}</div>);
  }

  export { EventContent };


const Title = styled.div`
  width: 100%;
  font-size: 48px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  `;
  const Description = styled.p`
  margin-bottom: 4px;
  margin-top: 4px;
  margin-right: 0px;
  margin-left: 0px;
  color: #E2001A;
`;


  const Container = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: -
  `;

export default Calendar;

