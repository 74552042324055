/**
 * @fileoverview table component for student overview page
 * @description it displays the students of a specific student
 * it uses a predefined table from material ui that is enhanced with additional functions
 *
 * @license MIT
 */

import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import GetRequest from "../../../components/getFormular";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { DialogContentText } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DropdownSelection from "../../../components/staticRoleSelectionTemplate";
import { useAuth } from "../../../auth/AuthContext";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { useEffect } from "react";
import SuccessDialog from "../../../components/successDialog";
import ErrorDialog from "../../../components/errorDialog";
import PatchRequest from "../../../components/patchFormula";
import DeleteRequest from "../../../components/deleteFormula";
import PostRequest from "../../../components/postFormula";
import { v4 as uuidv4 } from "uuid";
import { set } from "date-fns";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

/**
 * @description define the head cells of the table you want to display
 */
const headCells = [
  {
    id: "scanner",
    numeric: false,
    disablePadding: true,
    label: "Scanner",
  },
  {
    id: "room",
    numeric: false,
    disablePadding: false,
    label: "Raum",
  },

  {
    id: "isHealthy",
    numeric: false,
    disablePadding: false,
    label: "Funktionstüchtig?",
  },
];

/**
 * @description component that creates the table head
 * @param {*} props
 */
function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.label === "Funktionstüchtig?" ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

/**
 * @description component that creates the toolbar of the table
 * @param {*} props
 * @returns
 */
function EnhancedTableToolbar(props) {
  const { numSelected, selected, setSelected, roomMap } = props;
  const { isAdmin } = useAuth();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [changedScannerName, setChangedScannerName] = React.useState("");
  const [newScannerName, setNewScannerName] = React.useState("");
  const [selectedScannerOption, setSelectedScannerOption] = React.useState("");
  const [openCreateScanner, setOpenCreateScanner] = React.useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
  const [newScannerRoom, setNewScannerRoom] = React.useState("");
  const [changedScannerRoom, setChangedScannerRoom] = React.useState("");

  // when no data is found at the time of creating a new scanner
  const [createNoDataFound, setCreateNoDataFound] = React.useState(false);

  // when no room is found at the time of creating a new scanner
  const [noRoomFound, setNoRoomFound] = React.useState(false);

  //when no room is found at the time of changing the scanner
  const [changeNoRoomFound, setChangeNoRoomFound] = React.useState(false);

  //when no data is found at the time of changing the scanner
  const [changeNoDataFound, setChangeNoDataFound] = React.useState(false);

  const scannerOptions = ["Scanner bearbeiten", "Scanner löschen"];

  /**
   * @brief gets called when user wants to edit the scanner
   */
  const handleEdit = async () => {
    setOpenDialog(true);
  };

  /**
   * Closes the dialog and resets the state values.
   */
  const handleClose = () => {
    setChangedScannerName("");
    setChangedScannerRoom("");
    setChangeNoDataFound(false);
    setChangeNoRoomFound(false);
    setOpenDialog(false);
  };

  /**
   * Handles the closing of the create scanner dialog.
   */
  const handleCreateScannerClose = () => {
    setCreateNoDataFound(false);
    setNoRoomFound(false);
    setNewScannerName("");
    setNewScannerRoom("");
    setOpenCreateScanner(false);
  };

  /**
   * @brief refresehes the page after user confirms the action to update the scanner data
   */
  const handleCloseSuccessDialog = () => {
    setSuccessDialogOpen(false);
    window.location.reload();
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
    window.location.reload();
  };

  /**
   * @brief gets called when user selects his choice from the dropdown
   * @param {*} selectedScannerOption
   */
  const handleScannerSelection = (selectedScannerOption) => {
    console.log("Selected Option:", selectedScannerOption);
    setSelectedScannerOption(selectedScannerOption);
  };

  /**
   * @brief gets called after confirmation ot the user and delets the selected scanners
   */
  const handleDeleteScanner = async () => {
    setOpenDialog(false);
    try {
      await Promise.all(
        selected.map(async (id) => {
          const response = await DeleteRequest(`scanners/${id}`);
          console.log(response);
          console.log(`scanner with the ${id} was deleted`);
        })
      );
      console.log("All scanners werde succesfully deleted");
      setSuccessDialogOpen(true);
    } catch (error) {
      console.error("Error deleting the scanners", error);
      setErrorDialogOpen(true);
    }

    setSelected([]);
  };

  /**
   * @brief gets called when user confirms to change the name of the scanner
   */
  const handleChangeScanner = async () => {
    console.log("old room", changedScannerRoom);
    let dataToUpdate = {};

    // check if data was entered
    if (!changedScannerName && !changedScannerRoom) {
      setChangeNoDataFound(true);
      return;
    }

    // check whether changedScannerRomm is empty
    if (!changedScannerRoom) {
      // only update changedScannerName
      dataToUpdate = { name: changedScannerName };
    } else if (!changedScannerName) {
      // When only the room is changed
      // get id of the room
      const roomId = Object.keys(roomMap).find(
        (key) => roomMap[key] === changedScannerRoom
      );

      // check if room was entered
      if (!roomId) {
        setChangeNoRoomFound(true);
        return;
      }
      dataToUpdate = { roomId: roomId };
    } else {
      // if both arent empty, update both
      const roomId = Object.keys(roomMap).find(
        (key) => roomMap[key] === changedScannerRoom
      );
      if (!roomId) {
        console.error("Raum nicht gefunden");
        setErrorDialogOpen(true);
        return;
      }
      dataToUpdate = { name: changedScannerName, roomId: roomId };
    }

    console.log("data to update", dataToUpdate);

    try {
      await Promise.all(
        selected.map(async (id) => {
          const response = await PatchRequest({
            data: dataToUpdate,
            path: `scanners/${id}`,
          });
          console.log(response);
          console.log(`Gerät mit der ID ${id} wurde aktualisiert.`);
        })
      );
      console.log("Alle Geräte wurden erfolgreich aktualisiert.");
      setOpenDialog(false);
      setSuccessDialogOpen(true);
    } catch (error) {
      console.error("Fehler beim Aktualisieren der Geräte:", error);
      setOpenDialog(false);
      setErrorDialogOpen(true);
    }

    setSelected([]);
  };

  /**
   * Handles the creation of a new scanner.
   *
   * @returns {Promise<void>} A promise that resolves when the scanner is created successfully.
   */
  const handleCreateScanner = async () => {
    console.log("Neuer Gerätename:", newScannerName);
    const newScannerRoomId = Object.keys(roomMap).find(
      (key) => roomMap[key] === newScannerRoom
    );
    console.log("Neuer Raum:", newScannerRoomId);

    if (!newScannerName) {
      setCreateNoDataFound(true);
      return;
    }

    if (!newScannerRoomId) {
      setNoRoomFound(true);
      return;
    }

    try {
      const response = await PostRequest({
        data: {
          name: newScannerName,
          roomId: newScannerRoomId,
        },
        path: "scanners",
      });
      if (response.status === 201) {
        console.log("Gerät erfolgreich erstellt.");
        setOpenCreateScanner(false);
        setSuccessDialogOpen(true);
      } else {
        console.error("Fehler beim Erstellen des Geräts:", response);
        setOpenCreateScanner(false);
        setErrorDialogOpen(true);
      }
    } catch (error) {
      console.error("Fehler beim Erstellen des Geräts:", error);
      setOpenCreateScanner(false);
      setErrorDialogOpen(true);
    }
  };

  /**
   * Handles the confirmation action based on the selected scanner option.
   * If the selected option is "Scanner bearbeiten", calls the handleChangeScanner function.
   * If the selected option is "Scanner löschen", calls the handleDeleteScanner function.
   * @returns {Promise<void>} A promise that resolves when the confirmation action is completed.
   */
  const handleConfirm = async () => {
    if (selectedScannerOption === "Scanner bearbeiten") {
      handleChangeScanner();
    } else if (selectedScannerOption === "Scanner löschen") {
      handleDeleteScanner();
    }
  };

  /**
   * @brief gets called when user wants to change the scanner authentication token
   * patch request to server
   */
  const handleChangeScannerAuthenticationToken = async () => {
    try {
      await Promise.all(
        selected.map(async (id) => {
          const response = await PatchRequest({
            path: `scanners/${id}/authenticationToken`,
          });
          console.log(response);
          console.log(`Scanner mit der ID ${id} wurde aktualisiert.`);
          // for now, just save the received authentication token
        })
      );
      console.log("Alle Scanner wurden erfolgreich aktualisiert.");
      setSuccessDialogOpen(true);
    } catch (error) {
      console.error("Fehler beim Aktualisieren der Geräte:", error);
      setErrorDialogOpen(true);
    }

    console.log("change scanner authentication token");
  };

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Geräteübersicht
          </Typography>
        )}

        {isAdmin && (
          <Tooltip title="Edit">
            <IconButton
              onClick={() => {
                if (numSelected > 0) {
                  handleEdit();
                } else {
                  setOpenCreateScanner(true);
                }
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>

      <Dialog open={openDialog} onClose={handleClose} maxWidth="lg">
        <DialogTitle>Scanner bearbeiten</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <DropdownSelection
              options={scannerOptions}
              onSelect={handleScannerSelection}
              label={"Auswahl"}
            />
          </DialogContentText>

          {/* formula to change scanner name */}
          {selectedScannerOption === "Scanner bearbeiten" && (
            <>
              <DialogContent>
                <Typography variant="h6" gutterBottom>
                  Raum oder Name der ausgewählten Scanner ändern
                </Typography>

                <DialogContentText>
                  Geben Sie hier die veränderten Daten ein: (leere Felder werden
                  nicht geändert)
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Neuer Gerätename"
                  type="text"
                  fullWidth
                  value={changedScannerName}
                  onChange={(e) => setChangedScannerName(e.target.value)}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  label="Neuer Raum"
                  type="text"
                  fullWidth
                  value={changedScannerRoom}
                  onChange={(e) => setChangedScannerRoom(e.target.value)}
                />
                {/* no entered data found */}
                {changeNoDataFound && (
                  <DialogContentText style={{ color: "red" }}>
                    Keine Daten gefunden. Bitte geben Sie Daten ein, die Sie
                    ändern möchten.
                  </DialogContentText>
                )}
                {/* no valid room found */}
                {changeNoRoomFound && (
                  <DialogContentText style={{ color: "red" }}>
                    Raum nicht gefunden. Geben Sie einen existierenden Raumnamen
                    ein.
                  </DialogContentText>
                )}
              </DialogContent>
            </>
          )}

          {/* confirmation for deleting scanner */}
          {selectedScannerOption === "Scanner löschen" && (
            <>
              <DialogContent>
                <DialogContentText>
                  Sind Sie sicher, dass Sie diese(n) Scanner löschen möchten?
                </DialogContentText>
                <DialogContentText style={{ color: "red" }}>
                  ⚠ Achtung: Diese Aktion kann nicht rückgängig gemacht werden
                  und betrifft alle ausgewählten Scanner!{" "}
                </DialogContentText>
              </DialogContent>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={handleConfirm} color="primary">
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialog to create new scanner */}
      <Dialog
        open={openCreateScanner}
        onClose={() => setOpenCreateScanner(false)}
        maxWidth="lg"
      >
        <DialogTitle>Neuen Scanner erstellen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Geben Sie hier den neuen Scannernamen ein:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Neuer Scannername"
            type="text"
            fullWidth
            value={newScannerName}
            onChange={(e) => setNewScannerName(e.target.value)}
          />
          <DialogContentText>Geben Sie hier den Raum ein:</DialogContentText>
          <TextField
            margin="dense"
            label="Raum"
            type="text"
            fullWidth
            value={newScannerRoom}
            onChange={(e) => setNewScannerRoom(e.target.value)}
          />
          {/* no entered data found */}
          {createNoDataFound && (
            <DialogContentText style={{ color: "red" }}>
              Keinen Scanner Namen gefunden. Bitte geben Sie einen Namen für den
              Scanner ein.
            </DialogContentText>
          )}
          {/* no valid room found */}
          {noRoomFound && (
            <DialogContentText style={{ color: "red" }}>
              Raum nicht gefunden. Geben Sie einen existierenden Raumnamen ein.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateScannerClose}>Abbrechen</Button>
          <Button onClick={handleCreateScanner} color="primary">
            Erstellen
          </Button>
        </DialogActions>
      </Dialog>

      {/* success pop up */}
      <SuccessDialog
        open={successDialogOpen}
        onClose={handleCloseSuccessDialog}
        text="Aktion erfolgreich ausgeführt"
      />
      <ErrorDialog
        open={errorDialogOpen}
        onClose={handleCloseErrorDialog}
        errorMessage="Die gewünschte Aktion konnte nicht durchgeführt werden"
      />
    </>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
/**
 * component that gets imported in parent component
 * uses Toolbar, Head components to include more functions
 * @returns {JSX.Element}
 */
export default function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  // rows that are displayed in the table
  const [rows, setRows] = React.useState([]);
  const [scannerMap, setScannerMap] = React.useState({}); // Objekt zur Zuordnung von ID zu Scanner-Namen
  const [roomMap, setRoomMap] = React.useState({}); // Objekt zur Zuordnung von ID zu Raum-Namen

  /**
   * @brief fetches all existing scanners and rooms
   */
  const fetchScannerData = async (roomIdMap) => {
    try {
      const response = await GetRequest("scanners");
      console.log(response.data);
      const desiredScannerKeys = ["id", "name", "isHealthy", "roomId"];
      const transformedScanners = response.data.map((scanner) => {
        const transformedItem = {};
        desiredScannerKeys.forEach((key) => {
          if (key === "roomId") {
            console.log("Room ID: ", scanner[key]);
            transformedItem["room"] = roomIdMap[scanner[key]];
          } else {
            transformedItem[key] = scanner[key];
          }
        });
        return transformedItem;
      });
      console.log("Transformed Scanners: ", transformedScanners);
      setRows(transformedScanners);

      // Erstellen des Objekts zur Zuordnung von ID zu Scanner-Namen
      const scannerIdMap = {};
      transformedScanners.forEach((scanner) => {
        scannerIdMap[scanner.id] = scanner.name;
      });
      console.log("Scanner ID Map: ", scannerIdMap);
      setScannerMap(scannerIdMap);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRoomData = async () => {
    try {
      const response = await GetRequest("rooms");
      console.log(response.data);
      const roomIdMap = {};
      response.data.forEach((room) => {
        roomIdMap[room.id] = room.name;
      });
      console.log("Room ID Map: ", roomIdMap);
      setRoomMap(roomIdMap);
      fetchScannerData(roomIdMap);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * @brief fetches all existing rooms, scanners
   */
  useEffect(() => {
    fetchRoomData();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    // add rows to the dependencies array to ensure that visiblerows is recalculated when rows changes
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          setSelected={setSelected}
          selected={selected}
          roomMap={roomMap}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 600 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.room}</TableCell>
                    <TableCell align="right">
                      <span
                        style={{
                          backgroundColor: row.isHealthy
                            ? "#4caf50"
                            : "#E2001A",
                          color: "#fff",
                          padding: "5px 10px",
                          borderRadius: "25px",
                        }}
                      >
                        {row.isHealthy ? "Ja" : "Nein"}
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
