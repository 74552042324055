import React, { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "styled-components";
import { useAuth } from "../../../../auth/AuthContext";
import GetRequest from "../../../../components/getFormular";

/**
 * CourseFilter component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onSelect - The function to handle course selection.
 * @param {boolean} props.resetSelectedCourse - Indicates whether the selected course should be reset.
 * @returns {JSX.Element} The CourseFilter component.
 */
const CourseFilter = ({ onSelect, resetSelectedCourse }) => {
  const [selectedCourse, setSelectedCourse] = useState("");
  const [courseIds, setCourseIds] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [courses, setCourses] = useState([]);
  const { facultyId } = useAuth();

  // Reset selectedCourse, wenn resetSelectedCourse changes
  useEffect(() => {
    if (resetSelectedCourse) {
      setSelectedCourse("");
    }
  }, [resetSelectedCourse]);

  useEffect(() => {
    /**
     * Fetches courses based on the facultyId and updates the state with the fetched data.
     * If facultyId is provided, it fetches courses of the corresponding faculty.
     * If facultyId is not provided, it fetches all courses.
     */
    const fetchCourses = async () => {
      try {
        if (facultyId) {
          // Wenn facultyId vorhanden ist, nur die Kurse der entsprechenden Fakultät abrufen
          const response = await GetRequest(`faculties/${facultyId}/courses`);
          const coursesData = response.data;
          const coursesShortNames = coursesData.map(
            (course) => course.shortName
          );
          const coursesMap = coursesData.reduce((acc, course) => {
            acc[course.shortName] = course.id;
            return acc;
          }, {});

          setCourses(coursesShortNames);
          setCourseIds(coursesMap);
        } else {
          // no facultyId => admin => fetch all courses
          const response = await GetRequest("courses");
          const coursesData = response.data;
          const coursesShortNames = coursesData.map(
            (course) => course.shortName
          );
          const coursesMap = coursesData.reduce((acc, course) => {
            acc[course.shortName] = course.id;
            return acc;
          }, {});

          setCourses(coursesShortNames);
          setCourseIds(coursesMap);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchCourses();
  }, []);

  /**
   * @brief
   * @param {*} course
   */
  const handleCourseSelect = async (course) => {
    setSelectedCourse(course);
    setAnchorEl(null);
    onSelect(courseIds[course]);
  };

  return (
    <div>
      <Container>
        <label htmlFor="courses" style={labelStyle}>
          Kurs:{" "}
        </label>
        <Button
          id="course-button"
          aria-label="Kurse"
          aria-controls="course-menu"
          aria-haspopup="true"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<KeyboardArrowDownIcon />}
          style={buttonStyle}
        >
          {selectedCourse || "Kurs"}
        </Button>
        <Menu
          id="course-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {courses.map((course) => (
            <MenuItem key={course} onClick={() => handleCourseSelect(course)}>
              {course}
            </MenuItem>
          ))}
        </Menu>
      </Container>
    </div>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const labelStyle = {
  marginRight: "10px",
  width: "140px",
  minWidth: "140px",
};

const buttonStyle = {
  color: "black",
  border: "1px solid #E2001A",
  fontFamily: "Arial, sans-serif",
  textTransform: "none",
  fontSize: "20px",
};

export default CourseFilter;
