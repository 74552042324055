import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import FacultySelection from "./facultySelection";
import PostRequest from "../../../../components/postFormula";
import ConfirmDialog from "../../../../components/confirmDialog";
import SuccessDialog from "../../../../components/successDialog";
import ErrorDialog from "../../../../components/errorDialog";


/**
 * Renders the AccountDetailsManager component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.selectedRole - The selected role.
 * @param {Function} props.handleResetSelectedRole - The function to handle resetting the selected role.
 * @returns {JSX.Element} The AccountDetailsManager component.
 */
const AccountDetailsManager = ({ selectedRole, handleResetSelectedRole }) => {
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [facultyId, setFacultyId] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  /**
   * @brief resets all states wehen selectedRole changes
   */
  useEffect(() => {
    // Reset all fields when selectedRole changes
    setLastName("");
    setFirstName("");
    setEmail("");
    setFacultyId("");
  }, [selectedRole]);

  const handleSubmitButton = async () => {
    // check if selectedRole is admin
    if (selectedRole === "Admin") {
      setIsAdmin(true);
    }

    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  /**
   * @brief called when users confirms the dialog for adding the account
   */
  const handleConfirm = async () => {
    setOpenConfirmDialog(false);

    let managerData = {
      email,
      lastName,
      firstName,
      isAdmin,
    };

    if (selectedRole !== "Admin") {
      managerData = {
        ...managerData,
        facultyId,
      };
    }


    try {
      const response = await PostRequest({
        data: managerData,
        path: "managers",
      });

      if (response.status === 201) {
        setEmail("");
        setLastName("");
        setFirstName("");
        setFacultyId("");
        handleResetSelectedRole();

        setOpenSuccessDialog(true);
      } else {
        setOpenErrorDialog(true);
      }
    } catch (error) {
      console.log(error);
      setOpenErrorDialog(true);
    }
  };

  /**
   * @brief called when the user closes the success dialog
   */
  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
  };

  /**
   * @brief called when the user closes the error dialog
   */
  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  return (
    <>
      <Container>
        <label htmlFor="email" style={labelStyle}>
          E-Mail:
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          style={inputStyle}
        />
      </Container>
      <Container>
        <label htmlFor="name" style={labelStyle}>
          Name:
        </label>
        <input
          type="text"
          id="name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
          style={inputStyle}
        />
      </Container>
      <Container>
        <label htmlFor="firstName" style={labelStyle}>
          Vorname:
        </label>
        <input
          type="text"
          id="firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
          style={inputStyle}
        />
      </Container>
      {selectedRole !== "Admin" && (
        <FacultySelection onSelect={(value) => setFacultyId(value)} />
      )}

      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={handleSubmitButton}
        disabled={
          !email ||
          !lastName ||
          !firstName ||
          (selectedRole === "Admin" ? false : !facultyId)
        }
        style={{
          backgroundColor: "#E2001A",
          marginTop: "20px",
        }}
      >
        Account hinzufügen
      </Button>
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleConfirm}
        confirmText="Möchten Sie diesen Verwalter/Admin hinzufügen?"
        dialogTitle="Verwalter/Admin hinzufügen"
      />
      <SuccessDialog
        open={openSuccessDialog}
        onClose={handleCloseSuccessDialog}
        text="Verwalter/Admin wurde erfolgreich hinzugefügt."
      />
      <ErrorDialog
        open={openErrorDialog}
        onClose={handleCloseErrorDialog}
        errorMessage="Verwalter/Admin konnte nicht hinzugefügt werden. Überprüfen Sie, ob die E-Mail bereits für einen Account existiert"
      />
    </>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const labelStyle = {
  marginRight: "10px",
  width: "140px",
  minWidth: "140px",
};

const inputStyle = {
  border: "1px solid #E2001A",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  fontSize: "20px",
  padding: "8px 12px",
  borderRadius: "4px",
  flex: 1,
};

export default AccountDetailsManager;
