import React from 'react';
import styled from 'styled-components';

const ProgressBarContainer = styled.div`
  position: relative;
  margin-top: 30px;
  width: 100%;
  height: 10px;
  background-color: #D9D9D9;
  border-radius: 5px;
`;

const ProgressBarFill = styled.div`
  position: relative;
  height: 100%;
  width: ${({ percentage }) => percentage}%;
  background-color: #E2001A;
  border-radius: 5px;
`;

const PercentageText = styled.div`
  position: absolute;
  top: -15px;
  left: ${({ percentage }) => percentage}%;
  transform: translateX(-50%) translateY(-50%);
  color: #000; /* Farbe des Textes */
  font-weight: bolder;
`;

/**
 * Renders a progress bar component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.percentage - The percentage value for the progress bar.
 * @returns {JSX.Element} The rendered ProgressBar component.
 */
const ProgressBar = ({ percentage }) => {
  return (
    <ProgressBarContainer>
      <ProgressBarFill percentage={percentage} />
      <PercentageText percentage={percentage}>{percentage}%</PercentageText>
    </ProgressBarContainer>
  );
};

export default ProgressBar;
