import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  DialogActions,
} from "@mui/material";

/**
 * A dialog component for confirming actions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Whether the dialog is open or not.
 * @param {Function} props.onClose - The function to call when the dialog is closed.
 * @param {Function} props.onConfirm - The function to call when the confirm button is clicked.
 * @param {string} props.confirmText - The text to display in the dialog content.
 * @param {string} props.dialogTitle - The title of the dialog.
 * @returns {JSX.Element} The ConfirmDialog component.
 */
const ConfirmDialog = ({ open, onClose, onConfirm, confirmText, dialogTitle }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {confirmText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Abbrechen
        </Button>
        <Button onClick={onConfirm} color="primary">
          Bestätigen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

