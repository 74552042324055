import axios from "axios";

const BASE_URL = `https://api.studicheckin.afink.dev/`;

/**
 * Sends a POST request to the specified path with the provided data.
 *
 * @param {Object} options - The options for the POST request.
 * @param {Object} options.data - The data to be sent in the request body.
 * @param {string} options.path - The path to send the request to.
 * @returns {Promise} - A promise that resolves to the response of the POST request.
 * @throws {Error} - If an error occurs while sending the POST request.
 */
const PostRequest = async ({ data, path }) => {
  try {
    console.log("Sending POST request...");
    const response = await axios.post(`${BASE_URL}${path}`, data);
    console.log("POST request was successful!");
    return response; // Erfolgreiche Antwort zurückgeben
  } catch (error) {
    console.error("Error while sending POST request:", error);
    throw new Error("Fehler beim Senden des POST-Anforderung");
  }
};

export default PostRequest;
