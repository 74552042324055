/**
 * @fileoverview Login formular component for the login page.
 * @description allows admins, students to login to the corresponding dashboard
 */

import React, { useState } from "react";
import { Button, TextField, Typography, Container, Link } from "@mui/material";
import PostRequest from "../../../components/postFormula";

/**
 * this function returns the login formular and handles the login request for a user
 * @returns {JSX.Element} LoginFormula
 */
const EmailFormula = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await PostRequest({ data: { email }, path: "auth/forgot-password" });

      setSuccessMessage(
        "Ein Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail-Adresse gesendet."
      );
    } catch (error) {
      console.error(error.message);
      setError("Fehler beim Senden des Passwort-Zurücksetzen-Links");
    }
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" align="center" gutterBottom>
        Passwort zurücksetzen
      </Typography>
      <Typography variant="body2" align="center" gutterBottom>
        Geben Sie Ihre registrierte E-Mail-Adresse ein, um Anweisungen zum
        Zurücksetzen Ihres Passworts zu erhalten.
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          sx={{ marginBottom: 2 }}
          label="Email"
          variant="outlined"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          fullWidth
          required
        />
        <Typography variant="body2" align="center" gutterBottom>
          Sie besitzen bereits einen Account?{" "}
          <Link href="/login" underline="hover" color="secondary">
            Einloggen
          </Link>
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        {successMessage && (
          <Typography color="error">{successMessage}</Typography>
        )}
        <Button
          sx={{
            color: "white",
            backgroundColor: "#E2001A",
            "&:hover": {
              backgroundColor: "#E2001A",
            },
          }}
          type="submit"
          variant="contained"
          fullWidth
        >
          E-Mail senden
        </Button>
      </form>
    </Container>
  );
};

export default EmailFormula;
