/**
 * @fileoverview table component for student overview page
 * @description it displays the students of a specific student
 * it uses a predefined table from material ui that is enhanced with additional functions
 *
 * @license MIT
 */

import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import styled from "styled-components";

import SuccessDialog from "../../../components/successDialog";
import ErrorDialog from "../../../components/errorDialog";
import {
  Dialog,
  DialogContentText,
  DialogTitle,
  Button,
  DialogActions,
} from "@mui/material";
//reuse CourseFilter from creation of accounts
import CourseFilter from "../../accountsPage/components/addAccount/courseSelection";
import PatchRequest from "../../../components/patchFormula";
import GetRequest from "../../../components/getFormular";
import DeleteRequest from "../../../components/deleteFormula";

import { useEffect } from "react";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

/**
 * @description define the head cells of the table you want to display
 */
const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Nachname",
  },
  {
    id: "Vorname",
    numeric: false,
    disablePadding: false,
    label: "Vorname",
  },
  {
    id: "Kurs",
    numeric: false,
    disablePadding: false,
    label: "Kurs",
  },
];

/**
 * @description component that creates the table head
 * @param {*} props
 */
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,

    onRequestSort,
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

/**
 * @description component that creates the toolbar of the table
 * @param {*} props
 * @returns
 */
function EnhancedTableToolbar(props) {
  const { numSelected, setSelected, selected } = props;
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [regNumber, setRegNumber] = React.useState("");
  const [courseId, setCourseId] = React.useState("");
  const [resetSelectedCourse, setResetSelectedCourse] = React.useState(false);
  const [noDataEntered, setNoDataEntered] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(
    "Die gewünschte Aktion konnte nicht durchgeführt werden"
  );
  const [successMessage, setSuccessMessage] = React.useState(
    "Aktion erfolgreich ausgeführt"
  );

  /**
   * @brief refresehes the page after user confirms the action to update the student data
   */
  const handleCloseSuccessDialog = () => {
    setSuccessDialogOpen(false);
    window.location.reload();
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
    window.location.reload();
  };

  /**
   * @brief gets called when user wants to edit the student
   */
  const handleEditButton = async () => {
    setOpenDialog(true);
  };

  /**
   * @brief gets called when user wants to delete the selected students
   */
  const handleDeleteButton = async () => {
    console.log("delete button clicked");
    setOpenDeleteDialog(true);
  };

  //closes the dialog
  const handleClose = () => {
    setNoDataEntered(false);
    setOpenDialog(false);
  };

  const handleDeleteClose = () => {
    setOpenDeleteDialog(false);
  };

  /**
   *  @brief makes the patch request to change selected data
   */
  const handleConfirm = async () => {
    setNoDataEntered(false);
    const newData = {};
    if (email) {
      newData.email = email;
    }

    if (lastName) {
      newData.lastName = lastName;
    }

    if (firstName) {
      newData.firstName = firstName;
    }

    if (courseId) {
      newData.courseId = courseId;
    }

    if (regNumber) {
      // check if regNumber has the length of 7, if not open error dialog
      if (regNumber.length !== 7) {
        console.error("Matrikelnummer muss 7-stellig sein");
        setErrorMessage("Matrikelnummer muss 7-stellig sein");
        setOpenDialog(false);
        setErrorDialogOpen(true);
        return;
      }
      newData.regNumber = regNumber;
    }

    // check if at least one field is filled
    if (Object.keys(newData).length > 0) {
      console.log("newData", newData);
      try {
        await Promise.all(
          selected.map(async (id) => {
            const response = await PatchRequest({
              data: newData,
              path: `students/${id}`,
            });
            console.log(response);
          })
        );
        console.log("Alle Studenten wurden erfolgreich aktualisiert.");
        setOpenDialog(false);
        setSuccessDialogOpen(true);
      } catch (error) {
        console.error("Fehler beim Aktualisieren des Studenten:", error);
        setOpenDialog(false);
        setErrorDialogOpen(true);
      }
    } else {
      // Zeige eine Fehlermeldung oder führe andere Aktionen aus, wenn keine Daten eingegeben wurden
      console.error("Keine Daten eingegeben!");
      setNoDataEntered(true);
    }
  };

  const handleDeleteConfirm = async () => {
    setOpenDeleteDialog(false);
    try {
      await Promise.all(
        selected.map(async (id) => {
          const response = await DeleteRequest(`students/${id}`);
          console.log(`Student mit der ID ${id} wurde gelöscht.`);
        })
      );
      console.log("Alle ausgewählten Studenten wurden erfolgreich gelöscht.");
      setSuccessMessage(
        "Alle ausgewählten Studenten wurden erfolgreich gelöscht."
      );
      setSuccessDialogOpen(true);
    } catch (error) {
      console.error("Fehler beim Löschen der Studenten:", error);
      setErrorDialogOpen(true);
    }

    setSelected([]);
  };

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Studentenübersicht
          </Typography>
        )}
        {numSelected > 0 && (
          <div style={{ display: "flex" }}>
            <Tooltip title="Edit">
              <IconButton onClick={handleEditButton}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={handleDeleteButton}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </Toolbar>

      {/* dialog for editing existing accounts */}
      <Dialog open={openDialog} onClose={handleClose} maxWidth="lg">
        <DialogTitle>Student bearbeiten</DialogTitle>
        <DialogContentText
          style={{ color: "red", marginBottom: "10px", marginLeft: "40px" }}
        >
          ⚠ Achtung: Diese Aktion betrifft alle ausgewählten Studenten{" "}
        </DialogContentText>
        <Container>
          <label htmlFor="email" style={labelStyle}>
            E-Mail:
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={inputStyle}
          />
        </Container>
        {/* lastName Container */}
        <Container>
          <label htmlFor="name" style={labelStyle}>
            Name:
          </label>
          <input
            type="text"
            id="name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            style={inputStyle}
          />
        </Container>
        {/* firstName Container */}
        <Container>
          <label htmlFor="firstName" style={labelStyle}>
            Vorname:
          </label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            style={inputStyle}
          />
        </Container>
        {/* Matrikelnummer Container */}
        <Container>
          <label htmlFor="matrikelNummer" style={labelStyle}>
            Matrikelnummer:
          </label>
          <input
            type="number"
            id="matrikelNummer"
            value={regNumber}
            onChange={(e) => setRegNumber(e.target.value.slice(0, 7))}
            maxLength={7} // since the matrikelnummer is 7 digits long
            style={inputStyle}
          />
        </Container>

        {/* course container */}
        <Container>
          <CourseFilter
            onSelect={(value) => setCourseId(value)}
            resetSelectedCourse={resetSelectedCourse}
          />
        </Container>
        {noDataEntered ? (
          <DialogContentText
            style={{ color: "red", marginBottom: "10px", marginLeft: "40px" }}
          >
            Bitte geben Sie mindestens ein Feld ein
          </DialogContentText>
        ) : null}

        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={handleConfirm} color="primary">
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialog for deleting existing accounts */}
      <Dialog open={openDeleteDialog} onClose={handleClose} maxWidth="lg">
        <DialogTitle>Student löschen</DialogTitle>
        <DialogContentText
          style={{ color: "red", marginBottom: "10px", marginLeft: "40px" }}
        >
          ⚠ Achtung: Diese Aktion betrifft alle ausgewählten Studenten{" "}
        </DialogContentText>
        <DialogContentText
          style={{ color: "red", marginBottom: "10px", marginLeft: "40px" }}
        >
          Möchten Sie die ausgewählten Studenten wirklich löschen?
        </DialogContentText>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Abbrechen</Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Löschen
          </Button>
        </DialogActions>
      </Dialog>

      {/* success pop up */}
      <SuccessDialog
        open={successDialogOpen}
        onClose={handleCloseSuccessDialog}
        text="Aktion erfolgreich ausgeführt"
      />
      <ErrorDialog
        open={errorDialogOpen}
        onClose={handleCloseErrorDialog}
        errorMessage={errorMessage}
      />
    </>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

/**
 * component that gets imported in parent component
 * uses Toolbar, Head components to include more functions
 * @returns {JSX.Element}
 */
export default function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("lastName");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  // rows that are displayed in the table
  const [rows, setRows] = React.useState([]);
  const [courseId, setCourseId] = React.useState({});

  /**
   *  @description fetch all existing courses and save them to with their id as key
   */
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const courseResponse = await GetRequest("courses");
        const transformedCourses = {};
        courseResponse.data.forEach((item) => {
          transformedCourses[item.id] = item.shortName;
        });
        setCourseId(transformedCourses);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, []);

  /**
   * @description fetch all students and transform the data
   */
  useEffect(() => {
    const fetchStudents = async () => {
      if (Object.keys(courseId).length === 0) {
        console.log("CourseId ist leer. Warte auf Daten...");
        return;
      }

      try {
        console.log("fetching students");
        const studentResponse = await GetRequest("students");
        const desiredStudentKeys = ["id", "firstName", "lastName", "courseId"];
        const transformedStudents = studentResponse.data.map((item) => {
          const transformedItem = {};
          desiredStudentKeys.forEach((key) => {
            if (key === "courseId") {
              transformedItem["course"] = courseId[item[key]];
            } else {
              transformedItem[key] = item[key];
            }
          });
          return transformedItem;
        });
        console.log("transformedStudents", transformedStudents);
        setRows(transformedStudents);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };

    fetchStudents();
  }, [courseId]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    // add rows to the dependencies array to ensure that visiblerows is recalculated when rows changes
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          setSelected={setSelected}
          selected={selected}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 600 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.lastName}
                    </TableCell>
                    <TableCell align="left">{row.firstName}</TableCell>
                    <TableCell align="left">{row.course}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

const Container = styled.div`
  margin-bottom: 20px;
  margin-left: 40px;
  margin-right: 40px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const labelStyle = {
  marginRight: "10px",
  width: "140px",
  minWidth: "140px",
};

const inputStyle = {
  border: "1px solid #E2001A",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  fontSize: "20px",
  padding: "8px 12px",
  borderRadius: "4px",
  flex: 1,
};
