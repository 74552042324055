/**
 * @fileoverview Login page
 * @description This file is the login page for the user to log in to their account.
 * @returns {JSX.Element} Login page
 */

import React from "react";
import styled from "styled-components";
import LoginFormula from "./components/loginFormula";

/**
 * Renders the Login component.
 * @returns {JSX.Element} The rendered Login component.
 */
const Login = () => {
  return (
    <div>
      <Logo loading="lazy" src="DHlogo_red.png" alt="logo" />
      <LoginFormula />
    </div>
  );
};

export default Login;

const Logo = styled.img`
  width: 130px;
  height: auto;
  padding: 20px;
`;
