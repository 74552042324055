import React, { useState } from "react";
import RoleSelection from "./roleSelection";
import CourseDetails from "./courseDetails";
import FacultyDetails from "./facultyDetails";


/**
 * Represents a form for adding courses or faculties.
 * @component
 */
const AddCoursesForm = () => {
  const [selectedRole, setSelectedRole] = useState("");

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  return (
    <form>
      <h3>
        Geben Sie hier die benötigten Daten an, um einen neuen Kurs oder
        Fakultät zu erstellen
      </h3>
      <RoleSelection onSelect={handleRoleSelect} />

      {selectedRole === "Kurs" && <CourseDetails />}
      {selectedRole === "Fakultät" && <FacultyDetails />}
    </form>
  );
};

export default AddCoursesForm;
