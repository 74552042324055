/**
 * @fileoverview SignUp.js is the page for the user to enter his email to reset his password.
 *  @description It is a simple page with email prompt
 *
 * @returns {SignUp} SignUp page.
 *
 */

import React from "react";
import styled from "styled-components";
import EmailFormula from "./components/emailFormula";

/**
 * Renders the SetEmail component.
 * @returns {JSX.Element} The rendered SetEmail component.
 */
const SetEmail = () => {
  return (
    <div>
      <Logo loading="lazy" src="/DHlogo_red.png" alt="logo" />
      <EmailFormula />
    </div>
  );
};

export default SetEmail;

const Logo = styled.img`
  width: 130px;
  height: auto;
  padding: 20px;
`;
