/**
 * @fileoverview renders the course Overview page including the filter and the course table
 *
 * @returns {JSX.Element} CourseOverviewTable
 */

import React from "react";
import styled from "styled-components";
import EnhancedToolbar from "./table";
import MenuButton from "../../../components/menu";

const CourseOverviewTable = () => {
  return (
    <Container>
      <MenuButton />
      <EnhancedToolbar />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  color: #000;
  font-weight: 600;
  padding: 20px 100px;
  min-height: 72vh;
`;

export default CourseOverviewTable;
