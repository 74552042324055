import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

/**
 * @brief This component is responsible for the faculty selection in the form to create a new course, only shown to admin users
 * else the faculty is set by the managers current faculty
 * @param {*} param0
 * @returns
 */
const FacultySelection = ({ onSelect, resetRoleSelection }) => {
  const [selectedRole, setSelectedRole] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [faculties, setFaculties] = useState([]);
  const [facultiesMap, setFacultiesMap] = useState({});

  /**
   * @brief resets the selected role when resetRoleSelection changes
   */

  useEffect(() => {
    setSelectedRole("");
  }, [resetRoleSelection]);

  /**
   * @brief sets the selected role and closes the confirm dialog
   * @param {*} role
   */
  const handleRoleSelect = (role) => {
    setSelectedRole(role);
    setAnchorEl(null);
    console.log("Role selected:", role);
    // get the faculty id from the facultiesMap
    const facultyId = Object.keys(facultiesMap).find(
      (key) => facultiesMap[key] === role
    );
    onSelect(facultyId);
  };

  /**
   * @brief fetch all existing faculties
   */
  useEffect(() => {
    const fetchFaculties = async () => {
      try {
        const response = await axios.get(
          `https://api.studicheckin.afink.dev/faculties`
        );
        const faculties = response.data;

        const facultiesMap = {};
        faculties.forEach((faculty) => {
          facultiesMap[faculty.id] = faculty.name;
        });

        setFaculties(faculties);
        setFacultiesMap(facultiesMap);
      } catch (error) {
        console.error(error);
      }
    };
    fetchFaculties();
  }, []);

  return (
    <Container>
      <label htmlFor="roles" style={labelStyle}>
        Fakultät:
      </label>
      <Button
        id="role-button"
        aria-label="Kurse"
        aria-controls="role-menu"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        endIcon={<KeyboardArrowDownIcon />}
        style={{
          color: "black",
          border: "1px solid #E2001A",
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
          textTransform: "none",
          fontSize: "20px",
        }}
      >
        {selectedRole || "Fakultät"}
      </Button>

      <Menu
        id="role-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {faculties.map((faculty) => (
          <MenuItem
            key={faculty.id}
            onClick={() => handleRoleSelect(facultiesMap[faculty.id])}
          >
            {facultiesMap[faculty.id]}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const labelStyle = {
  marginRight: "10px",
  width: "140px",
  minWidth: "140px",
};

export default FacultySelection;
