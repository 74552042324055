/**
 * @fileoverview renders the course Overview page including the filter and the course table
 *
 * @returns {JSX.Element} CourseOverviewTable
 */

import React, { useState } from "react";
import styled from "styled-components";
import EnhancedToolbar from "./table";
import Filter from "./filter";
import MenuButton from "../../../components/menu";


/**
 * Renders the Course Overview table component.
 *
 * @component
 * @returns {JSX.Element} The Course Overview table component.
 */
const CourseOverviewTable = () => {
  const [courseOverview, setCourseOverwiew] = useState({});

  return (
    <Container>
      <MenuButton />
      <Filter setCourseOverwiew={setCourseOverwiew} />
      <EnhancedToolbar courseOverview={courseOverview} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  color: #000;
  font-weight: 600;
  padding: 20px 100px;
`;

export default CourseOverviewTable;
