/**
 * @fileoverview Filter component for the course overview page.
 * @description it allows the manager to select a specific course to display the students of that course.
 */

import React, { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAuth } from "../../../auth/AuthContext";
import GetRequest from "../../../components/getFormular";

// for styling
import styled from "styled-components";


/**
 * Filter component for selecting and applying filters to the course overview.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setCourseOverwiew - The function to set the course overview.
 * @returns {JSX.Element} The rendered Filter component.
 */
const Filter = ({ setCourseOverwiew }) => {
  // setting initial states
  const [selectedCourse, setSelectedCourse] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const [courses, setCourses] = useState([]);
  const {facultyId} = useAuth();
  const [courseIds, setCourseIds] = useState({});


  /**
   * @brief fetch all existing courses if admin, else fetch only courses of the faculty from manager
   */
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        if (facultyId) {
          const response = await GetRequest(`faculties/${facultyId}/courses`);
          const coursesData = response.data;
          const coursesShortNames = coursesData.map(
            (course) => course.shortName
          );
          const coursesMap = coursesData.reduce((acc, course) => {
            acc[course.shortName] = course.id;
            return acc;
          }, {});

          setCourses(coursesShortNames);
          setCourseIds(coursesMap);
        } else {
          const response = await GetRequest("courses");
          const coursesData = response.data;
          const coursesShortNames = coursesData.map(
            (course) => course.shortName
          );
          const coursesMap = coursesData.reduce((acc, course) => {
            acc[course.shortName] = course.id;
            return acc;
          }, {});

          setCourses(coursesShortNames);
          setCourseIds(coursesMap);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchCourses();
  }, [facultyId]);

  /**
   * @description applys the selected course to the course overview
   */
  const handleApplyFilter = () => {
    const courseId = courseIds[selectedCourse];
    const courseOverview = {
      courseId: courseId,
      courseName: selectedCourse,
    };
    setCourseOverwiew(courseOverview);
    setSelectedCourse("");
  };

  /**
   * adds the desired course to the selected course
   * @param course
   *
   */
  const handleCourseSelect = (course) => {
    setSelectedCourse(course);
    setAnchorEl(null);
  };

  /**
   * renders the filter component
   */
  return (
    <div>
      <h1>Filter</h1>
      <p>Bitte wählen Sie einen Kurs, um die aktuellen Studenten anzuzeigen.</p>

      {/* course container */}
      <Container>
        <label htmlFor="courses">Kurse: </label>
        <Button
          id="course-button"
          aria-label="Kurse"
          aria-controls="course-menu"
          aria-haspopup="true"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<KeyboardArrowDownIcon />}
          style={{
            color: "black",
            border: "1px solid #E2001A",
            fontFamily:
              "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
            textTransform: "none",
            fontSize: "20px",
          }}
        >
          {selectedCourse || "Kurse"}
        </Button>

        {/* menu for course container */}
        <Menu
          id="course-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {courses.map((course) => (
            <MenuItem key={course} onClick={() => handleCourseSelect(course)}>
              {course}
            </MenuItem>
          ))}
        </Menu>
      </Container>

      {/* Filter button to apply selected course */}

      <button
        onClick={handleApplyFilter}
        style={{
          padding: "10px 20px",
          backgroundColor: "#E2001A",
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
          fontSize: "20px",
          color: "white",
          border: "1px solid red",
          borderRadius: "5px",
          cursor: "pointer",
          margin: "0px 0px 40px 0px",
        }}
      >
        Anwenden
      </button>
    </div>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  label {
    margin-right: 10px;
  }
`;

export default Filter;
