import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import AxiosInterceptor from "./auth/axiosInterceptors";
import { AuthProvider } from "./auth/AuthContext";
import { useAuth } from "./auth/AuthContext";
import MainPage from "./pages/mainPage/mainPage";
import HelpPage from "./pages/helpPage/helpPage";
import CalendarPage from "./pages/kalenderPage/calendarPage";
import AttendancePage from "./pages/attendancePage/attendancePage";
import CourseOverviewPage from "./pages/courseOverviewPage/courseOverviewPage";
import StudentOverviewPage from "./pages/studentOverviewPage/studentOverviewPage";
import LecturePage from "./pages/lecturePage/lecturePage";
import EventMenuPage from "./pages/eventmenuPage/eventmenuPage";
import ScannerOverviewPage from "./pages/scannerOverviewPage/scannerOverviewPage";
import RoomOverviewPage from "./pages/roomOverviewPage/roomOverviewPage";
import SetEmail from "./pages/emailPage/emailPage";
import Accounts from "./pages/accountsPage/accountsPage";
import SetPassword from "./pages/passwordPage/password";
import Login from "./pages/loginPage/login";

/**
 * Renders the appropriate component based on the user's authentication status.
 * If the user is authenticated, it renders the specified `element` component.
 * If the user is not authenticated, it redirects to the login page.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isAuthenticated - Indicates whether the user is authenticated.
 * @param {React.Component} props.element - The component to render if the user is authenticated.
 * @returns {React.Component} - The rendered component.
 */
const AuthRoute = ({ element }) => {
  const { isAuthenticated, isLoading } = useAuth();
  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress color="error" />
      </Box>
    );
  } else {
    return isAuthenticated ? element : <Navigate to="/login" />;
  }
};

/**
 * Renders the main application component.
 *
 * @returns {JSX.Element} The JSX element representing the application.
 */
function App() {
  return (
    <Router>
      <AxiosInterceptor />
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="setemail" element={<SetEmail />} />
          <Route
            path="/auth/invitation"
            element={
              <SetPassword
                title="Account erstellen"
                linkText="Legen Sie ihr Passwort fest"
                action="invitation"
              />
            }
          />
          <Route
            path="/auth/reset-password"
            element={
              <SetPassword
                title="Passwort zurücksetzen"
                linkText="Legen Sie ihr neues Passwort fest"
                action="reset-password"
              />
            }
          />
          <Route path="/" element={<AuthRoute element={<MainPage />} />} />
          <Route path="/help" element={<AuthRoute element={<HelpPage />} />} />
          <Route
            path="/calendar"
            element={<AuthRoute element={<CalendarPage />} />}
          />
          <Route
            path="/attendance"
            element={<AuthRoute element={<AttendancePage />} />}
          />
          <Route
            path="/courseoverview"
            element={<AuthRoute element={<CourseOverviewPage />} />}
          />
          <Route
            path="/studentoverview"
            element={<AuthRoute element={<StudentOverviewPage />} />}
          />
          <Route
            path="/scanneroverview"
            element={<AuthRoute element={<ScannerOverviewPage />} />}
          />
          <Route
            path="/accounts"
            element={<AuthRoute element={<Accounts />} />}
          />
          <Route
            path="/lecture"
            element={<AuthRoute element={<LecturePage />} />}
          />
          <Route
            path="/eventmenu"
            element={<AuthRoute element={<EventMenuPage />} />}
          />
          <Route
            path="/roomoverview"
            element={<AuthRoute element={<RoomOverviewPage />} />}
          />
          <Route
            path="/setemail"
            element={<AuthRoute element={<SetEmail />} />}
          />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
