import React from "react";
import Footer from "../../components/footer";
import Navbar from "../../components/header";
import MainMenu from "./components/mainMenu";

/**
 * Represents the main page of the website.
 *
 * @returns {JSX.Element} The rendered main page component.
 */
const MainPage = () => {
  return (
    <div>
      <Navbar />
      <MainMenu />
      <Footer />
    </div>
  );
};


export default MainPage;
