import React from "react";
import styled from "styled-components";

/**
 * Represents the navigation bar component.
 * @component
 */
const Navbar = () => {
  const handleLogout = () => {
    localStorage.removeItem("Authorization");
    localStorage.removeItem("refreshToken");

    window.location.href = "/login";
  };

  return (
    <Container>
      <Logo loading="lazy" src="DHlogo_red.png" alt="logo" />
      <NavLinks>
        <NavLink href="/">
          <Icon src="/home-icon.png" alt="Home" />
          Home
        </NavLink>
        <NavLink href="/help">
          <Icon src="/information-icon.png" alt="Hilfe" />
          Hilfe
        </NavLink>
        <NavLink to="/logout" onClick={handleLogout}>
          <Icon src="exit-icon.png" alt="Abmelden" />
          Abmelden
        </NavLink>
      </NavLinks>
    </Container>
  );
};

const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ffffff; /* Hintergrundfarbe der Navbar */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Schatten unter der Navbar */

  @media (maxwidth: 991px) {
    flex-wrap: wrap;
  }
`;

const Logo = styled.img`
  width: 130px; /* Breite des Logos */
  height: auto; /* Automatische Höhe beibehalten */
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;
`;

const NavLink = styled.a`
  position: relative; /* Positionierung für das Icon */
  text-decoration: none;
  color: #333333; /* Textfarbe der Links */
  font-weight: bold;
  margin-right: 20px; /* Abstand zwischen den NavLink-Elementen */

  &:hover {
    color: #e2001a; /* Farbe bei Hover */
  }
`;

const Icon = styled.img`
  position: absolute; /* Positionieren Sie das Icon relativ zum Link */
  top: 50%; /* Zentrieren Sie das Icon vertikal */
  left: -20px; /* Platzieren Sie das Icon links vom Text */
  transform: translateY(-50%); /* Zentrieren Sie das Icon vertikal */
  width: 16px; /* Breite des Icons */
  height: auto; /* Automatische Höhe beibehalten */
`;

export default Navbar;
