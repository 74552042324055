import axios from "axios";
import { useEffect } from "react";

/**
 * Get the locally stored access token.
 * @returns {string|null} The access token if available, otherwise null.
 */
function getLocalAccessToken() {
  return localStorage.getItem("Authorization");
}

/**
 * Get the locally stored refresh token.
 * @returns {string|null} The refresh token if available, otherwise null.
 */
function getLocalRefreshToken() {
  return localStorage.getItem("refreshToken");
}

/**
 * Axios interceptor for handling authentication.
 * @returns {null} Null is returned as this component has no renderable output.
 */
const AxiosInterceptor = () => {
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        const token = getLocalAccessToken();
        if (token && !config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response) {
          if (error.response.status === 401 && !error.config._retry) {
            error.config._retry = true;
            // Access token has expired, refresh it
            const refreshToken = getLocalRefreshToken();

            if (refreshToken) {
              try {
                const newTokens = await axios.post(
                  `https://api.studicheckin.afink.dev/auth/refresh`,
                  {},
                  { headers: { Authorization: `Bearer ${refreshToken}` } }
                );

                // Update the request headers with the new access token
                error.config.headers[
                  "Authorization"
                ] = `Bearer ${newTokens.data.accessToken}`;

                // Update localStorage with new tokens
                localStorage.setItem(
                  "Authorization",
                  newTokens.data.accessToken
                );
                localStorage.setItem(
                  "refreshToken",
                  newTokens.data.refreshToken
                );
                // Retry the original request
                return axios.request(error.config);
              } catch (refreshError) {
                throw refreshError;
              }
            }
          }
        }
      }
    );

    // Cleanup-function for interceptors
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return null;
};

export default AxiosInterceptor;
