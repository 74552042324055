import React, { useState } from "react";
import RoleSelection from "./roleSelectionCourse";
import { useAuth } from "../../../../auth/AuthContext";
import CourseFilter from "./deleteCoursesDetails";
import FacultyFilter from "./deleteFacultiesDetails";

/**
 * Renders a form for deleting courses or faculties.
 *
 * @component
 * @example
 * return (
 *   <DeleteCoursesFrom />
 * )
 */
const DeleteCoursesFrom = () => {
  const [selectedRole, setSelectedRole] = useState("");
  const { isAdmin } = useAuth();

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  return (
    <form>
      {isAdmin ? (
        <h3>
          Geben Sie hier die benötigten Daten an, um einen Kurs oder eine
          Fakultät zu löschen
        </h3>
      ) : (
        <h3>
          Geben Sie hier die benötigten Daten an, um einen Kurs zu löschen
        </h3>
      )}
      {/* course or faculty */}
      <RoleSelection onSelect={handleRoleSelect} />
      {/* course selection and confirmation to delete */}
      {selectedRole === "Kurs" && <CourseFilter />}

      {/* faculty selection and confirmation to delete */}
      {isAdmin && selectedRole === "Fakultät" && <FacultyFilter />}
    </form>
  );
};

export default DeleteCoursesFrom;
