import Footer from "../../components/footer";
import Navbar from "../../components/header";
import Calendar from "./components/calendar";
import styled from "styled-components";

/**
 * Renders the LecturePage component.
 * This component displays the navbar, calendar, and footer.
 *
 * @returns {JSX.Element} The rendered LecturePage component.
 */
const LecturePage = () => {
  return (
    <div>
      <Navbar />
      <Calendardiv>
      <Calendar/> 
      </Calendardiv>*
      <Footer />
    </div>
  );
};

const Calendardiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 5%; /* Default padding */
  
  @media screen and (min-width: 800px) {
    padding: 0 15%; /* Add 10% padding on screens larger than 768px */
  }
`;

export default LecturePage;
